<template>
  <v-card class="accounting-document d-flex flex-column" style="height: 100%;">
    <v-toolbar color="grey darken-3" dark elevation="1" style="flex: 0">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-dialog v-model="selection.show" max-width="800" persistent>
      <v-card height="90vh" style="position:relative;">
        <v-card-text class="px-5 pt-3 pb-2" style="height: 76px">
          <v-text-field v-model="selection.search"
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        color="grey"
                        placeholder="введіть текст для пошуку"
                        class="mb-2"
          />
        </v-card-text>
        <v-card-text class="px-5 py-1"
                     style="height: 76px;"
                     v-if="selection.selected.length"
        >
          <div>Обрано працівників:</div>
          <v-chip-group>
            <v-chip
                v-for="(person, idx) in selection.selected"
                :key="`s-p-n-${idx}`"
                close
                color="success"
                label
                @click:close="onSelectionPersonClose(person.person_id)"
            >
              {{ person.name }}
            </v-chip>
          </v-chip-group>
        </v-card-text>
        <v-card-text class="px-5 pt-2" style="overflow: auto; height: calc(100% - 160px);"
                     :style="`${!selection.selected.length ? ' height: calc(100% - 160px)' : ' height: calc(100% - 236px)'}`"
        >
          <v-list class="py-0" dense>
            <v-list-item class="elevation-4 mb-2"
                         v-for="(person, person_idx) in personSelection"
                         :key="`person-selection-${person_idx}`"
                         @click="onSelectionPersonClick(person)"
            >
              <v-list-item-action>
                <v-checkbox :value="!!selection.selected.find(i=>i.person_id === person.id)" hide-details
                            class="mt-0 pt-0" color="secondary"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content style="font-weight: 400 !important;">
                <v-list-item-subtitle style="font-size: .96rem; font-weight: 400 !important; color: #2d2d2d"
                                      class="mb-2">
                  {{ person.full_name }} {{ `(${getPersonType(person.person_type, person.full_time_type)}) ` }}
                </v-list-item-subtitle>
                <v-list-item-subtitle style="font-size: .94rem; font-weight: 400 !important;">
                  <v-chip label small class="mr-1" color="grey lighten-2" v-if="getHireAndReleaseDate(person)">
                    {{ getHireAndReleaseDate(person) }}
                  </v-chip>
                  <v-chip label small class="mr-1" color="grey lighten-2" v-if="person.subdivision_name">
                    {{ person.subdivision_name }}
                  </v-chip>
                  <v-chip label small class="mr-1" color="grey darken-1" dark v-if="person.position_name">
                    {{ person.position_name }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions style="height: 76px; position: absolute; bottom: 0; left: 0; right: 0">
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn depressed text color="secondary darken-1" @click="addSelectionPersonToPerson" class="button-accept">
            <v-icon left>mdi-content-save</v-icon>
            Зберегти
          </v-btn>
          <v-btn depressed text tile color="grey" @click="closeSelectionModal">
            <v-icon left>mdi-close</v-icon>
            Відмінити
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text class="pt-0 pb-0" style="flex: 1; display: flex; flex-direction: column; overflow: hidden;">
      <div class="document-header" style="flex: 0">
        <div class="document-header-row px-0 pt-2 pb-3">
          <div style="flex: 0 0 160px; margin-right: 16px">
            <date_input v-model="date" label="Дата: " :time="true" required @userChange="onDateChange" id="date"/>
          </div>
          <div style="flex: 0 0 110px; margin-right: 16px">
            <string_input v-model="number" label="Номер: " :disabled="true"/>
          </div>
          <div style="flex: 0 0 180px; margin-right: 16px">
            <select_input
                v-model="subdivision_id"
                :computed-action="'SELECT_SUBDIVISION_LIST_ELEMENTS'"
                :computed-getter="'get_subdivision_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                label="Підрозділ"
                :show-select-on-focus="false"
                :required="false"
                :select-button-object="{
                          name: 'SUBDIVISION_ELEMENTS',
                          group: '',
                          hierarchy: false,
                          only_groups: false,
                          title: 'Список підрозділ',
                          element_title: 'Підрозділ',
                          show: true
                        }"
            />
          </div>
          <div style="flex: 0 0 100px; margin-right: 16px">
            <select_input
                v-model="currency_id"
                :computed-action="'SELECT_CURRENCY_LIST_ELEMENTS'"
                :computed-getter="'get_currency_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                label="Валюта: "
                :select-button-object="{
                                name: 'CURRENCY_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список валют',
                                element_title: 'Валюта',
                                show: true
                              }"
            />
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px">
            <month_input v-model="month" label="Місяць нарахування: " required @userChange="onMonthChange" id="month"/>
          </div>
          <div style="flex: 0 0 60; margin-right: 16px">
            <v-checkbox
                v-model="is_advance" label="Це аванс" color="success"
                hide-details class="mt-4 pt-0"
                style="font-size: 12px !important;"
                @change="onAdvanceChange"
            />
          </div>
          <div style="flex: 1; align-self: flex-end">
            <v-btn small @click.stop="fillByAll"
                   style="height: 28px; margin-bottom: 2px"
                   :disabled="!date || !month"
            >Заповнити
            </v-btn>
          </div>
        </div>
      </div>
      <div class="document-body pt-0 pb-1" style="flex: 1; display: flex; flex-direction: column; overflow: hidden;">
        <div class="px-4 pt-2 pb-0" style="flex: 1; display: flex; height: 100%;">
          <v-row style="height: 100%;">
            <v-col cols="12" sm="5" md="3" class="grey lighten-4 pb-0" style="overflow: auto; height: 100%;">
              <div class="person-list">
                <div>
                  <v-btn small block class="mb-2" color="grey lighten-5"
                         @click.stop="openSelection"
                         :disabled="!date || !month"
                  >
                    Додати працівника
                  </v-btn>
                </div>
                <div class="person-item elevation-3"
                     :class="person.person_id === selected_person ? 'selected' : ''"
                     v-for="person in persons"
                     :key="`person-${person.row_num}`"
                     @click.stop="onPersonClick(person)"
                >
                  <div class="person-order">
                    <v-btn small icon @click.stop="deletePersonDialog(person)">
                      <v-icon size="19" :color="person.person_id === selected_person ? 'white' : 'grey darken-3'">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="person-title">
                    <div class="person-name">
                      {{ person.full_name }} {{ `(${getPersonType(person.person_type, person.full_time_type)}) ` }}
                    </div>
                    <div class="person-subtitle" style="margin-top: 2px" v-if="getHireAndReleaseDate(person)">
                      <v-chip small label
                              :color="person.person_id === selected_person ? 'grey darken-2' : 'grey lighten-3'"
                              :dark="person.person_id === selected_person"
                              style="height: 22px !important;">
                        {{ getHireAndReleaseDate(person) }}
                      </v-chip>
                    </div>
                  </div>
                  <div class="person-selected-icon">
                    <v-icon size="22" dark>mdi-check-all</v-icon>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="7" md="9" class="grey lighten-3 pb-0" style="overflow: hidden; height: 100%;">
              <v-tabs class="custom-tabs without" color="success" style="height: 100%; " v-model="tab">
                <v-tab>
                  Нарахування
                </v-tab>
                <v-tab v-if="!is_advance">
                  Індексація
                </v-tab>
                <v-tab v-if="!is_advance">
                  Внески
                </v-tab>
                <v-tab v-if="!is_advance">
                  ПДФО
                </v-tab>
                <v-tab v-if="!is_advance">
                  Результат
                </v-tab>
                <v-tab v-if="!is_advance">
                  Проводки
                </v-tab>

                <v-tab-item class="pa-0" style="height: 100%;">
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="!!(this.date && this.month && this.selected_person)"
                           class="success mr-2" :disabled="!this.date || !this.month || !this.selected_person"
                           @click.stop="addCharge"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                  </div>
                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col" style="flex: 0 0 280px">
                            Працівник
                          </div>
                          <div class="document-table-col" style="flex: 0 0 210px">
                            Вид нарахування
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px">
                            Категорія
                          </div>
                          <div class="document-table-col-group" style="flex: 0 0 120px">
                            <div class="document-table-col">
                              Вид розрах.
                            </div>
                            <div class="document-table-col">
                              Розмір
                            </div>
                          </div>
                          <div class="document-table-col-group" style="flex: 0 0 110px">
                            <div class="document-table-col">
                              Період з
                            </div>
                            <div class="document-table-col">
                              Період по
                            </div>
                          </div>
                          <div class="document-table-col-group" style="flex: 0 0 54px">
                            <div class="document-table-col">
                              Днів
                            </div>
                            <div class="document-table-col">
                              Годин
                            </div>
                          </div>
                          <div class="document-table-col-group" style="flex: 0 0 60px">
                            <div class="document-table-col">
                              Днів <br> (норма)
                            </div>
                            <div class="document-table-col">
                              Годин <br> (норма)
                            </div>
                          </div>
                          <div class="document-table-col" style="flex: 0 0 140px">
                            Результат
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in filter_charge"
                           :key="`charge-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ charges.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deleteCharge(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 280px">
                          <select_input
                              v-model="charge.person_id"
                              :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                              :computed-getter="'get_personal_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :required="true"
                              :disabled="!!charge.person_id"
                              transparent
                              :select-button-object="{
                                                        name: 'PERSONAL_ELEMENTS',
                                                        group: '',
                                                        hierarchy: false,
                                                        only_groups: false,
                                                        title: 'Список працівників',
                                                        element_title: 'Працівник',
                                                        show: true
                                                      }"
                              small
                              @selectChange="onPersonChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 210px">
                          <select_input
                              v-model="charge.accrual_id"
                              :computed-action="'SELECT_ACCRUAL_LIST_ELEMENTS'"
                              :computed-getter="'get_accrual_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              small
                              transparent
                              :select-button-object="{
                                name: 'ACCRUAL_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список видів нарахувань',
                                element_title: 'Вид нарахування',
                                show: true
                              }"
                              @selectChange="onChargeCharge"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 110px">
                          <select_input
                              v-model="charge.accrual_group"
                              :import-getter="{ name: 'accrual_group_select' }"
                              :input-value-as-value="false"
                              :row_num="charge.row_num"
                              small
                              transparent
                          />

                        </div>
                        <div class="document-table-col-group" style="flex: 0 0 120px">
                          <div class="document-table-col" style="font-weight: 500; font-style: oblique;">
                            {{ getCalcTypeName(charge.accrual_count_type) }}
                          </div>
                          <div class="document-table-col">
                            <number_input v-model="charge.tariff" transparent v-decimal :required="false" small
                                          :row_num="charge.row_num"
                                          @userChange="onChargeChange"
                            />
                          </div>
                        </div>
                        <div class="document-table-col-group" style="flex: 0 0 110px">
                          <div class="document-table-col">
                            <date_input :row_num="charge.row_num" v-model="charge.date_start" small transparent/>
                          </div>
                          <div class="document-table-col">
                            <date_input :row_num="charge.row_num" v-model="charge.date_end" small transparent/>
                          </div>
                        </div>
                        <div class="document-table-col-group" style="flex: 0 0 54px">
                          <div class="document-table-col">
                            <number_input :row_num="charge.row_num" v-model="charge.days"
                                          v-decimal small transparent
                                          :required="false"
                                          @userChange="onChargeChange"
                            />
                          </div>
                          <div class="document-table-col">
                            <number_input :row_num="charge.row_num" v-model="charge.hours"
                                          v-decimal small transparent
                                          :required="false"
                                          @userChange="onChargeChange"
                            />
                          </div>
                        </div>
                        <div class="document-table-col-group" style="flex: 0 0 60px">
                          <div class="document-table-col">
                            <number_input :row_num="charge.row_num" v-model="charge.days_n"
                                          v-decimal small transparent
                                          :required="false"
                                          @userChange="onChargeChange"
                            />
                          </div>
                          <div class="document-table-col">
                            <number_input :row_num="charge.row_num" v-model="charge.hours_n"
                                          v-decimal small transparent
                                          :required="false"
                                          @userChange="onChargeChange"
                            />
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 140px">
                          <number_input :row_num="charge.row_num" v-model="charge.result" v-decimal transparent
                                        :input_style="`font-weight: bold; ${getSumColor(charge.result)}`"
                                        :required="false"
                                        @userChange="onChargeResultChange"
                          />
                        </div>
                      </div>
                      <div class="document-table-footer mt-2">
                        <div class="document-table-row">
                          <div class="document-table-col"
                               style="flex: 0 0 1015px; text-align: right; padding-right: 6px">
                            Всього:
                          </div>
                          <div class="document-table-col" style="flex: 0 0 140px; padding-left: 10px">
                            {{ footer_total.charge.result | formatToFixed | formatNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>

                <v-tab-item class="pa-0" style="height: 100%;" v-if="!is_advance">
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="!!(this.date && this.month && this.selected_person)"
                           class="success mr-2" :disabled="!this.date || !this.month || !this.selected_person"
                           @click.stop="addIndexation"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                  </div>
                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col one-row" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 260px">
                            Працівник
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 110px">
                            Група
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 160px">
                            Базовий місяць
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 140px">
                            База
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 160px">
                            Індекс
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 140px">
                            Результат
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in filter_indexation"
                           :key="`indexation-${charge.row_num}`"
                      >
                        <div class="document-table-col one-row" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ indexation.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deleteIndexation(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 260px">
                          <select_input
                              v-model="charge.person_id"
                              :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                              :computed-getter="'get_personal_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :required="true"
                              transparent
                              :disabled="!!charge.person_id"
                              :select-button-object="{
                                                        name: 'PERSONAL_ELEMENTS',
                                                        group: '',
                                                        hierarchy: false,
                                                        only_groups: false,
                                                        title: 'Список працівників',
                                                        element_title: 'Працівник',
                                                        show: true
                                                      }"
                              small
                              @selectChange="onPersonChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 110px">
                          <select_input
                              v-model="charge.accrual_group"
                              :import-getter="{ name: 'accrual_group_select' }"
                              :input-value-as-value="false"
                              :row_num="charge.row_num"
                              small
                              transparent
                          />
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 160px">
                          <month_input :row_num="charge.row_num" v-model="charge.base_month" small transparent
                          />
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 140px">
                          <number_input :row_num="charge.row_num" v-model="charge.base" v-decimal small transparent
                                        :required="false"
                                        @userChange="onIndexationChange"
                          />
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 160px">
                          <number_input :row_num="charge.row_num" v-model="charge.index" v-decimal small transparent
                                        :required="false"
                                        @userChange="onIndexationChange"
                          />
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 140px">
                          <number_input :row_num="charge.row_num" v-model="charge.result" v-decimal small transparent
                                        :required="false"
                                        @userChange="onIndexationResultChange"
                          />
                        </div>
                      </div>
                      <div class="document-table-footer mt-2">
                        <div class="document-table-row">
                          <div class="document-table-col"
                               style="flex: 0 0 900px; text-align: right; padding-right: 6px">
                            Всього:
                          </div>
                          <div class="document-table-col" style="flex: 0 0 140px; padding-left: 10px">
                            {{ footer_total.indexation.result | formatToFixed | formatNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>

                <v-tab-item class="pa-0" style="height: 100%;" v-if="!is_advance">
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="!!(this.date && this.month && this.selected_person)"
                           class="success mr-2" :disabled="!this.date || !this.month || !this.selected_person"
                           @click.stop="addContribution"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                  </div>
                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col" style="flex: 0 0 260px">
                            Працівник
                          </div>
                          <div class="document-table-col" style="flex: 0 0 130px">
                            Група
                          </div>
                          <div class="document-table-col" style="flex: 0 0 170px">
                            Вид внеску
                          </div>
                          <div class="document-table-col" style="flex: 0 0 80px">
                            Процент
                          </div>
                          <div class="document-table-col" style="flex: 0 0 135px">
                            Місяць
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px">
                            База
                          </div>
                          <div class="document-table-col" style="flex: 0 0 90px">
                            В т.ч дорах. до мін.
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px">
                            Результат
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px">
                            Результат (дорах в т.ч)
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in filter_contribution"
                           :key="`contribution-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ contribution.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deleteContribution(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 260px">
                          <select_input
                              v-model="charge.person_id"
                              :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                              :computed-getter="'get_personal_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :required="true"
                              :disabled="!!charge.person_id"
                              :select-button-object="{
                                                        name: 'PERSONAL_ELEMENTS',
                                                        group: '',
                                                        hierarchy: false,
                                                        only_groups: false,
                                                        title: 'Список працівників',
                                                        element_title: 'Працівник',
                                                        show: true
                                                      }"
                              small
                              transparent
                              @selectChange="onPersonChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 130px">
                          <select_input
                              v-model="charge.accrual_group"
                              :import-getter="{ name: 'accrual_group_select' }"
                              :input-value-as-value="false"
                              :row_num="charge.row_num"
                              small
                              transparent
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 170px">
                          <select_input
                              v-model="charge.tax_id"
                              :computed-action="'SELECT_TAXES_LIST_ELEMENTS'"
                              :computed-getter="'get_taxes_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              small
                              :select-button-object="{
                                name: 'TAXES_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список податків/внесків',
                                element_title: 'Податок/внесок',
                                show: true
                              }"
                              transparent
                              @selectChange="onContributionTaxChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 80px">
                          <number_input :row_num="charge.row_num" v-model="charge.tariff" v-decimal small transparent
                                        @userChange="onContributionChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 135px">
                          <month_input :row_num="1" v-model="charge.month" small transparent/>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px">
                          <number_input :row_num="charge.row_num" v-model="charge.base" v-decimal small transparent
                                        input_style="font-style: oblique; font-weight: 500"
                                        @userChange="onContributionChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 90px">
                          <number_input :row_num="charge.row_num" v-model="charge.base_add" v-decimal small transparent
                                        :required="false" @userChange="onContributionChange"/>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px">
                          <number_input :row_num="charge.row_num" v-model="charge.result" v-decimal small transparent
                                        :input_style="'font-weight: bold'"
                                        :required="false"
                                        @userChange="onContributionResultChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px">
                          <number_input :row_num="charge.row_num" v-model="charge.result_add" v-decimal small
                                        transparent
                                        :input_style="'font-weight: bold'"
                                        :required="false"
                                        @userChange="onContributionResultChange"
                          />
                        </div>
                      </div>
                      <div class="document-table-footer mt-2">
                        <div class="document-table-row">
                          <div class="document-table-col"
                               style="flex: 0 0 845px; text-align: right; padding-right: 6px">
                            Всього:
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px; padding-left: 10px">
                            {{ footer_total.contribution.base | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 90px; padding-left: 10px">
                            {{ footer_total.contribution.base_add | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px; padding-left: 10px">
                            {{ footer_total.contribution.result | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px; padding-left: 10px">
                            {{ footer_total.contribution.result_add | formatToFixed | formatNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>

                <v-tab-item class="pa-0" style="height: 100%;" v-if="!is_advance">
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="!!(this.date && this.month && this.selected_person)"
                           class="success mr-2" :disabled="!this.date || !this.month || !this.selected_person"
                           @click.stop="addPDFO"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                  </div>
                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col one-row" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 260px">
                            Працівник
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 135px">
                            Місяць
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 140px">
                            Група
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 100px">
                            Код доходу
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 130px">
                            Рахунок Дт
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 100px">
                            База
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 200px">
                            Пільга
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 50px">
                            К-сть пільг
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 90px">
                            ПДФО
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 80px">
                            Військовий <br> збір
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in filter_tax_pdfo"
                           :key="`tax_pdfo-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ tax_pdfo.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deletePDFO(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 260px">
                          <select_input
                              v-model="charge.person_id"
                              :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                              :computed-getter="'get_personal_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :required="true"
                              :disabled="!!charge.person_id"
                              :select-button-object="{
                                                        name: 'PERSONAL_ELEMENTS',
                                                        group: '',
                                                        hierarchy: false,
                                                        only_groups: false,
                                                        title: 'Список працівників',
                                                        element_title: 'Працівник',
                                                        show: true
                                                      }"
                              small
                              transparent
                              @selectChange="onPersonChange"
                          />
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 135px">
                          <month_input :row_num="charge.row_num" v-model="charge.month" small transparent/>
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 140px">
                          <select_input
                              v-model="charge.accrual_group"
                              :import-getter="{ name: 'accrual_group_select' }"
                              :input-value-as-value="false"
                              :row_num="charge.row_num"
                              small
                              transparent
                          />
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 100px">
                          <select_input
                              v-model="charge.income_type_pix_id"
                              :computed-action="'SELECT_INCOME_TYPE_PIX_LIST_ELEMENTS'"
                              :computed-getter="'get_income_type_pix_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :required="true"
                              :item-text="'additional_field_1'"
                              :select-button-object="{
                                                        name: 'INCOME_TYPE_PIX_ELEMENTS',
                                                        group: '',
                                                        hierarchy: false,
                                                        only_groups: false,
                                                        title: 'Коди доходів',
                                                        element_title: 'Код доходу',
                                                        show: true
                                                      }"
                              small
                              transparent
                          />
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 130px">
                          <select_input
                              v-model="charge.chart_of_account"
                              :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                              :computed-getter="'get_chart_of_accounts_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              small
                              transparent
                              :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список рахунків бух.обліку',
                                element_title: 'Рахунок бух.обліку',
                                show: true
                              }"
                              :required="true"
                          />
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 100px">
                          <number_input :row_num="charge.row_num" v-model="charge.base" v-decimal small transparent
                                        input_style="font-style: oblique; font-weight: 500"
                                        :required="false"
                                        @userChange="onPdfoChange"
                          />
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 200px">
                          <select_input
                              v-model="charge.privilege_type"
                              :import-getter="{ name: 'social_privilege_select' }"
                              :input-value-as-value="false"
                              :show-search-on-focus="true"
                              :item-search="'text'"
                              :nudge-width="'100%'"
                              small
                              :row_num="charge.row_num"
                              transparent
                              :required="false"
                              @selectChange="onPdfoChange"
                          />
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 50px">
                          <number_input :row_num="charge.row_num" v-model="charge.privilege_count" v-decimal small
                                        transparent
                                        :required="false"
                                        @userChange="onPdfoChange"
                          />
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 90px">
                          <number_input :row_num="charge.row_num" v-model="charge.result" v-decimal small transparent
                                        input_style="font-weight: bold;"
                                        :required="false"
                                        @userChange="onPdfoResultChange"
                          />
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 80px">
                          <number_input :row_num="charge.row_num" v-model="charge.result_military" v-decimal small
                                        transparent
                                        input_style="font-weight: bold;"
                                        :required="false"
                                        @userChange="onPdfoResultChange"
                          />
                        </div>
                      </div>
                      <div class="document-table-footer mt-2">
                        <div class="document-table-row">
                          <div class="document-table-col"
                               style="flex: 0 0 835px; text-align: right; padding-right: 6px">
                            Всього:
                          </div>
                          <div class="document-table-col" style="flex: 0 0 350px; padding-left: 10px">
                            {{ footer_total.tax_pdfo.base | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 90px; padding-left: 10px">
                            {{ footer_total.tax_pdfo.result | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 80px; padding-left: 10px">
                            {{ footer_total.tax_pdfo.result_military | formatToFixed | formatNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>

                <v-tab-item class="pa-0" style="height: 100%;" v-if="!is_advance">
                  <div class="document-table without-command">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col one-row" style="flex: 0 0 60px; font-size: .82rem">
                            № п/п
                          </div>
                          <div class="document-table-col one-row"
                               style="flex: 0 0 260px; padding-left: 8px; font-size: .82rem">
                            Працівник
                          </div>
                          <div class="document-table-col one-row"
                               style="flex: 0 0 140px; padding-left: 8px; font-size: .82rem">
                            Нараховано
                          </div>
                          <div class="document-table-col one-row"
                               style="flex: 0 0 140px; padding-left: 8px; font-size: .82rem">
                            Внески
                          </div>
                          <div class="document-table-col one-row"
                               style="flex: 0 0 140px; padding-left: 8px; font-size: .82rem">
                            ПДФО + <br> Військовий збір
                          </div>
                          <div class="document-table-col one-row"
                               style="flex: 0 0 140px; padding-left: 8px; font-size: .82rem">
                            До виплати
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge, charge_idx) in filter_charge_result"
                           :key="`charge_result-${charge.row_num}`"
                      >
                        <div class="document-table-col one-row"
                             style="flex: 0 0 60px; font-size: .82rem; font-weight: 400;">
                          {{ charge_idx + 1 }}
                        </div>
                        <div class="document-table-col one-row result"
                             style="flex: 0 0 260px; padding-left: 8px; font-size: .82rem; font-weight: 400;">
                          <select_input
                              v-model="charge.person_id"
                              :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                              :computed-getter="'get_personal_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="0"
                              :show-select-on-focus="false"
                              :required="true"
                              :disabled="true"
                              small
                              transparent
                          />
                        </div>
                        <div class="document-table-col one-row result"
                             style="flex: 0 0 140px; padding-left: 8px; font-size: .82rem">
                          {{ charge.charge_result }}
                        </div>
                        <div class="document-table-col one-row result"
                             style="flex: 0 0 140px; padding-left: 8px; font-size: .82rem">
                          {{ charge.contribution }}
                        </div>
                        <div class="document-table-col one-row result"
                             style="flex: 0 0 140px; padding-left: 8px; font-size: .82rem">
                          {{ charge.tax }}
                        </div>
                        <div class="document-table-col one-row result"
                             style="flex: 0 0 140px; padding-left: 8px; font-weight: 600; font-size: .82rem;">
                          {{ charge.to_pay }}
                        </div>
                      </div>
                      <div class="document-table-footer mt-2">
                        <div class="document-table-row">
                          <div class="document-table-col"
                               style="flex: 0 0 320px; text-align: right; padding-right: 6px">
                            Всього:
                          </div>
                          <div class="document-table-col" style="flex: 0 0 140px; padding-left: 10px">
                            {{ footer_total.charge_result.charge_result | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 140px; padding-left: 10px">
                            {{ footer_total.charge_result.contribution | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 140px; padding-left: 10px">
                            {{ footer_total.charge_result.tax | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 140px; padding-left: 10px">
                            {{ footer_total.charge_result.to_pay | formatToFixed | formatNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>

                <v-tab-item class="pa-0" style="height: 100%;" v-if="!is_advance">
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="!!(this.date && this.month && this.selected_person)"
                           class="success mr-2" :disabled="!this.date || !this.month || !this.selected_person"
                           @click.stop="addAccounting"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                  </div>
                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col" style="flex: 0 0 200px">
                            Працівник
                          </div>
                          <div class="document-table-col" style="flex: 0 0 160px">
                            Рахунок Дт
                          </div>
                          <div class="document-table-col-group" style="flex: 0 0 180px">
                            <div class="document-table-col">
                              Субконто 1
                            </div>
                            <div class="document-table-col">
                              Субконто 2
                            </div>
                            <div class="document-table-col">
                              Субконто 3
                            </div>
                          </div>
                          <div class="document-table-col" style="flex: 0 0 160px">
                            Рахунок Кт
                          </div>
                          <div class="document-table-col-group" style="flex: 0 0 180px">
                            <div class="document-table-col">
                              Субконто 1
                            </div>
                            <div class="document-table-col">
                              Субконто 2
                            </div>
                            <div class="document-table-col">
                              Субконто 3
                            </div>
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Сума
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in filter_accounting_result"
                           :key="`accounting-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ accounting.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deleteAccounting(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 200px">
                          <select_input
                              v-model="charge.person_id"
                              :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                              :computed-getter="'get_personal_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :required="true"
                              transparent
                              :disabled="!!charge.person_id"
                              :select-button-object="{
                                                        name: 'PERSONAL_ELEMENTS',
                                                        group: '',
                                                        hierarchy: false,
                                                        only_groups: false,
                                                        title: 'Список працівників',
                                                        element_title: 'Працівник',
                                                        show: true
                                                      }"
                              small
                              @selectChange="onPersonChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 160px">
                          <select_input
                              v-model="charge.debit_chart_of_account_id"
                              :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                              :computed-getter="'get_chart_of_accounts_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              small
                              transparent
                              :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список рахунків бух.обліку',
                                element_title: 'Рахунок бух.обліку',
                                show: true
                              }"
                              :required="true"
                              @selectChange="afterDebitChartOfAccountChange"
                          />
                        </div>
                        <div class="document-table-col-group" style="flex: 0 0 180px">
                          <div class="document-table-col" style="font-weight: 500; font-style: oblique;">
                            <select_input
                                v-model="charge.debit_detail_1_id"
                                :computed-action="subconto_type_select_setting[charge.debit_detail_1_type].computed_action"
                                :computed-getter="subconto_type_select_setting[charge.debit_detail_1_type].computed_getter"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="0"
                                :label="subconto_type_select_setting[charge.debit_detail_1_type].label"
                                :show-select-on-focus="false"
                                :select-button-object="{
                                name: subconto_type_select_setting[charge.debit_detail_1_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.debit_detail_1_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.debit_detail_1_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.debit_detail_1_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.debit_detail_1_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.debit_detail_1_type].select_button_object.element_title,
                                show: true
                              }"
                                small
                                transparent
                                hide-label
                                required
                                v-if="charge.debit_detail_1_type"
                            />
                            <div style="height: 20px;" v-else></div>
                          </div>
                          <div class="document-table-col">
                            <select_input
                                v-model="charge.debit_detail_2_id"
                                :computed-action="subconto_type_select_setting[charge.debit_detail_2_type].computed_action"
                                :computed-getter="subconto_type_select_setting[charge.debit_detail_2_type].computed_getter"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="0"
                                :label="subconto_type_select_setting[charge.debit_detail_2_type].label"
                                :show-select-on-focus="false"
                                :select-button-object="{
                                name: subconto_type_select_setting[charge.debit_detail_2_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.debit_detail_2_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.debit_detail_2_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.debit_detail_2_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.debit_detail_2_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.debit_detail_2_type].select_button_object.element_title,
                                show: true
                              }"
                                small
                                transparent
                                hide-label
                                v-if="charge.debit_detail_2_type"
                            />
                            <div style="height: 20px;" v-else></div>
                          </div>
                          <div class="document-table-col">
                            <select_input
                                v-model="charge.debit_detail_3_id"
                                :computed-action="subconto_type_select_setting[charge.debit_detail_3_type].computed_action"
                                :computed-getter="subconto_type_select_setting[charge.debit_detail_3_type].computed_getter"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="0"
                                :label="subconto_type_select_setting[charge.debit_detail_3_type].label"
                                :show-select-on-focus="false"
                                :select-button-object="{
                                name: subconto_type_select_setting[charge.debit_detail_3_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.debit_detail_3_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.debit_detail_3_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.debit_detail_3_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.debit_detail_3_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.debit_detail_3_type].select_button_object.element_title,
                                show: true
                              }"
                                small
                                transparent
                                hide-label
                                v-if="charge.debit_detail_3_type"
                            />
                            <div style="height: 20px;" v-else></div>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 160px">
                          <select_input
                              v-model="charge.credit_chart_of_account_id"
                              :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                              :computed-getter="'get_chart_of_accounts_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              small
                              transparent
                              :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список рахунків бух.обліку',
                                element_title: 'Рахунок бух.обліку',
                                show: true
                              }"
                              @selectChange="afterCreditChartOfAccountChange"
                          />
                        </div>
                        <div class="document-table-col-group" style="flex: 0 0 180px">
                          <div class="document-table-col" style="font-weight: 500; font-style: oblique;">
                            <select_input
                                v-model="charge.credit_detail_1_id"
                                :computed-action="subconto_type_select_setting[charge.credit_detail_1_type].computed_action"
                                :computed-getter="subconto_type_select_setting[charge.credit_detail_1_type].computed_getter"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="0"
                                :label="subconto_type_select_setting[charge.credit_detail_1_type].label"
                                :show-select-on-focus="false"
                                :select-button-object="{
                                name: subconto_type_select_setting[charge.credit_detail_1_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.credit_detail_1_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.credit_detail_1_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.credit_detail_1_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.credit_detail_1_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.credit_detail_1_type].select_button_object.element_title,
                                show: true
                              }"
                                small
                                transparent
                                hide-label
                                v-if="charge.credit_detail_1_type"
                            />
                            <div style="height: 20px;" v-else></div>
                          </div>
                          <div class="document-table-col">
                            <select_input
                                v-model="charge.credit_detail_2_id"
                                :computed-action="subconto_type_select_setting[charge.credit_detail_2_type].computed_action"
                                :computed-getter="subconto_type_select_setting[charge.credit_detail_2_type].computed_getter"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="0"
                                :label="subconto_type_select_setting[charge.credit_detail_2_type].label"
                                :show-select-on-focus="false"
                                :select-button-object="{
                                name: subconto_type_select_setting[charge.credit_detail_2_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.credit_detail_2_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.credit_detail_2_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.credit_detail_2_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.credit_detail_2_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.credit_detail_2_type].select_button_object.element_title,
                                show: true
                              }"
                                small
                                transparent
                                hide-label
                                v-if="charge.credit_detail_2_type"
                            />
                            <div style="height: 20px;" v-else></div>
                          </div>
                          <div class="document-table-col">
                            <select_input
                                v-model="charge.credit_detail_3_id"
                                :computed-action="subconto_type_select_setting[charge.credit_detail_3_type].computed_action"
                                :computed-getter="subconto_type_select_setting[charge.credit_detail_3_type].computed_getter"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="0"
                                :label="subconto_type_select_setting[charge.credit_detail_3_type].label"
                                :show-select-on-focus="false"
                                :select-button-object="{
                                name: subconto_type_select_setting[charge.credit_detail_3_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.credit_detail_3_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.credit_detail_3_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.credit_detail_3_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.credit_detail_3_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.credit_detail_3_type].select_button_object.element_title,
                                show: true
                              }"
                                small
                                transparent
                                hide-label
                                v-if="charge.credit_detail_3_type"
                            />
                            <div style="height: 20px;" v-else></div>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.sum" v-decimal transparent
                                        :input_style="`font-weight: bold; ${getSumColor(charge.sum)}`"
                                        :required="false"
                                        @userChange="onChargeResultChange"
                          />
                        </div>
                      </div>
                      <div class="document-table-footer mt-2">
                        <div class="document-table-row">
                          <div class="document-table-col"
                               style="flex: 0 0 950px; text-align: right; padding-right: 6px">
                            Всього:
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                            {{ footer_total.charge.result | formatToFixed | formatNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
    <v-card-actions style="flex: 0">
      <template>
        <v-spacer></v-spacer>
        <v-btn depressed color="grey lighten-3" v-if="!conducted" :disabled="isNew"
               :loading="getModalLoading"
               @click="doConducted(true)">
          <v-icon color="success">mdi-file-check-outline</v-icon>
        </v-btn>
        <v-btn depressed color="grey lighten-3" v-if="conducted" :disabled="isNew"
               :loading="getModalLoading"
               @click="doConducted(false)">
          <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
        </v-btn>
        <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
               class="button-accept">
          <v-icon left>mdi-content-save</v-icon>
          Провести та закрити
        </v-btn>
        <v-btn depressed text tile color="grey" @click="closeModal">
          <v-icon left>mdi-close</v-icon>
          Закрити
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  GET_PERSONAL_LIST_ELEMENTS,
  SELECT_TAXES_LIST_ELEMENTS,
  SELECT_ACCRUAL_LIST_ELEMENTS,
  SELECT_PERSONAL_LIST_ELEMENTS,
  SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS,
  SELECT_INCOME_TYPE_PIX_LIST_ELEMENTS,
  SELECT_SUBDIVISION_LIST_ELEMENTS,
  CREATE_DOCUMENT_SALARY_CHARGE_ELEMENTS,
  UPDATE_DOCUMENT_SALARY_CHARGE_ELEMENTS,
  REMOVE_DOCUMENT_SALARY_CHARGE_ELEMENTS,
  CONDUCTED_DOCUMENT_SALARY_CHARGE_ELEMENTS
} from "@/store/actions/accounting/list";
import {mapActions, mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {beginOfMonth, endOfMonth, format_date_from_python} from "@/utils/icons"
import {formatDate} from "@/filters";
import salaryChargeAPI from "@/utils/axios/accounting/documents/salary_charge"
import {maxBy} from "lodash";
import {subconto_type_select_setting} from '@/utils/accounting'
import chartOfAccountAPI from "@/utils/axios/accounting/chart_of_account";

import ModalAccountingComponentMixin from "@/mixins/modal_account_component";

const modalDeleteId = 'salary_charge_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_Salary_Charge.vue",
  mixins: [ModalAccountingComponentMixin],
  components: {
    select_input: () => import("@/components/accounting/input/document_select_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    date_input: () => import("@/components/accounting/input/document_date_input"),
    month_input: () => import("@/components/accounting/input/document_month_input"),
    number_input: () => import("@/components/accounting/input/document_number_input"),
  },
  data() {
    return {
      tab: 0,
      subconto_type_select_setting,
      selection: {
        show: false,
        search: '',
        selected: []
      },
      selected_person: null,
      month: this.item.month || null,
      subdivision_id: this.item.subdivision_id || null,
      currency_id: null,
      taxes: [],
      persons: [],
      charges: [],
      indexation: [],
      contribution: [],
      charge_result: [],
      accounting: [],
      tax_pdfo: [],
      is_advance: false,
      social_privilege_data: {
        'privilege_169_1_1_100': {base_counter: 1, children: false},
        'privilege_169_1_2_100': {base_counter: 1, children: true},
        'privilege_169_1_3a_150': {base_counter: 1.5, children: true},
        'privilege_169_1_3b_150': {base_counter: 1.5, children: true},
        'privilege_169_1_3d_150': {base_counter: 1.5, children: false},
        'privilege_169_1_3e_150': {base_counter: 1.5, children: false},
        'privilege_169_1_3g_150': {base_counter: 1.5, children: false},
        'privilege_169_1_3g1_150': {base_counter: 1.5, children: false},
        'privilege_169_1_4a_200': {base_counter: 2, children: false},
        'privilege_169_1_4b_200': {base_counter: 2, children: false},
        'privilege_169_1_4g_200': {base_counter: 2, children: false},
        'privilege_169_1_4g1_200': {base_counter: 2, children: false},
        'privilege_169_1_4v_200': {base_counter: 2, children: false},
      },
      footer_total: {
        charge: {result: 0},
        indexation: {result: 0},
        contribution: {base: 0, base_vocation: 0, base_add: 0, result: 0, result_add: 0},
        tax_pdfo: {base: 0, result: 0, result_military: 0},
        charge_result: {charge_result: 0, contribution: 0, tax: 0, to_pay: 0},
      }
    }
  },
  methods: {
    ...mapActions(
        {
          getPersonal: GET_PERSONAL_LIST_ELEMENTS,
        }
    ),
    doConducted(payload) {
      if (this.isNew) return

      this.$store.dispatch(CONDUCTED_DOCUMENT_SALARY_CHARGE_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
          .then(data => {
            if (data) {
              const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
              const color = payload ? 'success' : 'error'
              this.conducted = payload
              this.$store.commit(ALERT_SHOW, { text, color })
            }

          })
    },
    onAdvanceChange(payload) {
      if (payload) {
        this.tab = 0
      }
    },
    getChartsOfAccounts() {
      chartOfAccountAPI.get_all()
          .then(response => response.data)
          .then(data => {
            this.charts = data
          })
    },
    afterDebitChartOfAccountChange(payload) {
      const row = this.accounting.find(i => i.row_num === payload.row_num)
      if (!row) return
      if (!payload) return

      row.debit_detail_1_type = null
      row.debit_detail_1_id = null
      row.debit_detail_2_type = null
      row.debit_detail_2_id = null
      row.debit_detail_3_type = null
      row.debit_detail_3_id = null


      if (payload) {
        if ('text' in payload && 'value' in payload) {
          chartOfAccountAPI.get_by_id({item_id: payload.value})
              .then(response => response.data)
              .then(data => {
                const details = data.details || []
                const obj_1 = details.find(i => i.order === 1)
                const obj_2 = details.find(i => i.order === 2)
                const obj_3 = details.find(i => i.order === 3)

                if (obj_1) {
                  row.debit_detail_1_type = obj_1.detail_type
                }
                if (obj_2) {
                  row.debit_detail_2_type = obj_2.detail_type
                }
                if (obj_3) {
                  row.debit_detail_3_type = obj_3.detail_type
                }
              })
        } else {
          const details = payload.details || []
          const obj_1 = details.find(i => i.order === 1)
          const obj_2 = details.find(i => i.order === 2)
          const obj_3 = details.find(i => i.order === 3)

          if (obj_1) {
            row.debit_detail_1_type = obj_1.detail_type
          }
          if (obj_2) {
            row.debit_detail_2_type = obj_2.detail_type
          }
          if (obj_3) {
            row.debit_detail_3_type = obj_3.detail_type
          }
        }

      }
    },
    afterCreditChartOfAccountChange(payload) {
      const row = this.accounting.find(i => i.row_num === payload.row_num)
      if (!row) return
      if (!payload) return

      row.credit_detail_1_type = null
      row.credit_detail_1_id = null
      row.credit_detail_2_type = null
      row.credit_detail_2_id = null
      row.credit_detail_3_type = null
      row.credit_detail_3_id = null


      if (payload) {
        if ('text' in payload && 'value' in payload) {
          chartOfAccountAPI.get_by_id({item_id: payload.value})
              .then(response => response.data)
              .then(data => {
                const details = data.details || []
                const obj_1 = details.find(i => i.order === 1)
                const obj_2 = details.find(i => i.order === 2)
                const obj_3 = details.find(i => i.order === 3)

                if (obj_1) {
                  this.credit_detail_1_type = obj_1.detail_type
                }
                if (obj_2) {
                  this.credit_detail_2_type = obj_2.detail_type
                }
                if (obj_3) {
                  this.credit_detail_3_type = obj_3.detail_type
                }
              })
        } else {
          const details = payload.details || []
          const obj_1 = details.find(i => i.order === 1)
          const obj_2 = details.find(i => i.order === 2)
          const obj_3 = details.find(i => i.order === 3)

          if (obj_1) {
            row.credit_detail_1_type = obj_1.detail_type
          }
          if (obj_2) {
            row.credit_detail_2_type = obj_2.detail_type
          }
          if (obj_3) {
            row.credit_detail_3_type = obj_3.detail_type
          }
        }

      }
    },
    addSelectionPersonToPerson() {
      const persons = []
      this.selection.selected.filter(i => !i.present).forEach(p => {
        const cripple_category = p.person.cripple_category
        persons.push(p.person_id)
        this.persons.push(
            {
              person_id: p.person.id,
              full_name: p.person.full_name,
              person_type: p.person.person_type,
              cripple: cripple_category !== null,
              hire_date: p.person.hire_date,
              release_date: p.person.release_date,
              cripple_category: cripple_category,
              row_num: this.getNewRowNum('persons')
            }
        )

      })

      if (persons.length) {
        salaryChargeAPI.fill_by_all({
          month: this.month,
          subdivision_id: this.subdivision_id,
          persons: persons
        })
            .then(response => response.data)
            .then(data => {
              data.charge.forEach(p => {
                this.charges.push(Object.assign({}, p, {row_num: this.getNewRowNum('charges')}))
              })
              data.indexing.forEach(p => {
                this.indexation.push(Object.assign({}, p, {row_num: this.getNewRowNum('indexation')}))
              })
              data.esv.forEach(p => {
                this.contribution.push(Object.assign({}, p, {row_num: this.getNewRowNum('contribution')}))
              })
              data.pdfo.forEach(p => {
                this.tax_pdfo.push(Object.assign({}, p, {row_num: this.getNewRowNum('tax_pdfo')}))
              })
              this.calcResultAll()
              this.getTotals()
              this.buildAccounting()
            })
      }
      if (!this.selected_person) {
        if (this.persons.length) {
          this.selected_person = this.persons[0].person_id
        }
      }
      this.selection.show = false
      this.selection.selected = []
    },
    closeSelectionModal() {
      this.selection.show = false
      this.selection.selected = []
    },
    onSelectionPersonClose(payload) {
      const person = this.selection.selected.find(i => i.person_id === payload)
      if (person) {
        this.selection.selected.splice(this.selection.selected.indexOf(person), 1)
      }
    },
    getShortName(payload) {
      if (!payload) return ''
      const spliced = payload.split(' ')

      if (spliced.length <= 3) {
        return `${spliced[0]} ${spliced[1].substring(0, 1)}. ${spliced[2].substring(0, 1)}`
      }
      if (spliced.length === 2) {
        return `${spliced[0]} ${spliced[1].substring(0, 1)}`
      }
      if (spliced.length === 1) {
        return `${spliced[0]}`
      }
    },
    onSelectionPersonClick(payload) {
      const person = this.selection.selected.find(i => i.person_id === payload.id)
      if (person) {
        this.selection.selected.splice(this.selection.selected.indexOf(person), 1)
      } else {
        const person_table = this.persons.find(i => i.person_id === payload.id) || {}
        this.selection.selected.push(
            {
              person_id: payload.id,
              name: this.getShortName(payload.full_name),
              present: (person_table || {}).person_id !== undefined,
              person: Object.assign({}, payload)
            }
        )
      }
    },
    openSelection() {
      this.getPersonal({})
          .then(response => response.data)
          .then(() => {
            this.selection.show = true
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    getSumColor(result) {
      if ((result || 0) < 0) {
        return 'color: var(--v-error-base)'
      }
      if ((result || 0) > 0) {
        return 'color: var(--v-success-darken1)'
      }
      return 'color: inherit'
    },
    onMonthChange() {
      if (this.month && this.date) {
        this.getStartInfo()
      }
    },
    onDateChange() {
      if (this.month && this.date) {
        this.getStartInfo()
      }
    },
    deletePerson(payload) {
      const person_id = payload.person_id

      this.persons = this.persons.filter(item => item.person_id !== person_id)
      this.charges = this.charges.filter(item => item.person_id !== person_id)
      this.indexation = this.indexation.filter(item => item.person_id !== person_id)
      this.contribution = this.contribution.filter(item => item.person_id !== person_id)
      this.tax_pdfo = this.tax_pdfo.filter(item => item.person_id !== person_id)
      this.charge_result = this.charge_result.filter(item => item.person_id !== person_id)
      this.accounting = this.accounting.filter(item => item.person_id !== person_id)
      this.getTotals()

      if (this.selected_person) {
        const row = this.persons.find(i => i.person_id === this.selected_person)
        if (!row && this.persons.length) {
          this.selected_person = this.persons[0].person_id
        }
      }
    },
    onPersonClick(payload) {
      if (this.selected_person === payload.person_id) {
        this.selected_person = null
      } else {
        this.selected_person = payload.person_id
      }
    },
    returnChartDetails(chart) {
      const row = {'account_detail_type_1': null, 'account_detail_type_2': null, 'account_detail_type_3': null}
      if (!chart) return row
      if (!chart.details) return row

      chart.details.forEach(i => {
        row[`account_detail_type_${i.order || 1}`] = i.detail_type
      })
      return row
    },
    buildAccounting() {
      const chart_contribution = '651'
      const chart_pdfo = '6411'
      const chart_military = '642'
      const chart_social_hospital = '378'
      const chart_debit_social = this.charts.find(c => c.code === chart_social_hospital)

      const military_tax_id = this.start_info.military_tax_id
      const cost_item_zarp = this.start_info.cost_item_zarp

      const charge_accounting = []
      this.charges.forEach(i => {
        const hospital_social = i.accrual_group === 'hospital_by_social'
        const person = this.start_info.persons.find(p => p.person_id === i.person_id)
        const accounting = `${i.person_id}-${hospital_social ? chart_debit_social.id : person.chart_of_account_id}-${i.chart_of_account}`
        const chart_debit = hospital_social
            ? chart_debit_social
            : this.charts.find(c => c.id === person.chart_of_account_id)
        const debit_detail = this.returnChartDetails(chart_debit)
        const chart_credit = this.charts.find(c => c.id === i.chart_of_account)
        const credit_detail = this.returnChartDetails(chart_credit)

        let debit_detail_1_value = null
        let debit_detail_2_value = null

        if (debit_detail.account_detail_type_1 !== null) {
          if (debit_detail.account_detail_type_1 === 'subdivision') {
            debit_detail_1_value = person.subdivision_id
          }
          if (debit_detail.account_detail_type_1 === 'cost_items') {
            debit_detail_1_value = cost_item_zarp
          }
        }

        if (debit_detail.account_detail_type_2 !== null) {
          if (debit_detail.account_detail_type_2 === 'cost_items') {
            debit_detail_2_value = cost_item_zarp
          }
        }

        const row = charge_accounting.find(a => a.key === accounting)
        if (!row) {
          charge_accounting.push(
              {
                key: accounting,
                person_id: i.person_id,
                debit_chart_of_account_id: chart_debit.id,
                debit_detail_1_type: debit_detail.account_detail_type_1,
                debit_detail_1_id: debit_detail_1_value,
                debit_detail_2_type: debit_detail.account_detail_type_2,
                debit_detail_2_id: debit_detail_2_value,
                debit_detail_3_type: debit_detail.account_detail_type_3,
                credit_chart_of_account_id: chart_credit.id,
                credit_detail_1_type: credit_detail.account_detail_type_1,
                credit_detail_1_id: credit_detail.account_detail_type_1 !== null ? person.person_id : null,
                credit_detail_2_type: credit_detail.account_detail_type_2,
                credit_detail_3_type: credit_detail.account_detail_type_3,
                sum: i.result || 0
              }
          )
        } else {
          row.sum += i.result || 0
        }
      })
      this.indexation.forEach(i => {
        const person = this.start_info.persons.find(p => p.person_id === i.person_id)
        const accounting = `${i.person_id}-${person.chart_of_account_id}-${i.chart_of_account}`
        const chart_debit = this.charts.find(c => c.id === person.chart_of_account_id)
        const debit_detail = this.returnChartDetails(chart_debit)
        const chart_credit = this.charts.find(c => c.id === i.chart_of_account)
        const credit_detail = this.returnChartDetails(chart_debit)

        let debit_detail_1_value = null
        let debit_detail_2_value = null

        if (debit_detail.account_detail_type_1 !== null) {
          if (debit_detail.account_detail_type_1 === 'subdivision') {
            debit_detail_1_value = person.subdivision_id
          }
          if (debit_detail.account_detail_type_1 === 'cost_items') {
            debit_detail_1_value = cost_item_zarp
          }
        }

        if (debit_detail.account_detail_type_2 !== null) {
          if (debit_detail.account_detail_type_2 === 'cost_items') {
            debit_detail_2_value = cost_item_zarp
          }
        }

        const row = charge_accounting.find(a => a.key === accounting)
        if (!row) {
          charge_accounting.push(
              {
                key: accounting,
                person_id: i.person_id,
                debit_chart_of_account_id: chart_debit.id,
                debit_detail_1_type: debit_detail.account_detail_type_1,
                debit_detail_1_id: debit_detail_1_value,
                debit_detail_2_type: debit_detail.account_detail_type_2,
                debit_detail_2_id: debit_detail_2_value,
                debit_detail_3_type: debit_detail.account_detail_type_3,
                credit_chart_of_account_id: chart_credit.id,
                credit_detail_1_type: credit_detail.account_detail_type_1,
                credit_detail_1_id: credit_detail.account_detail_type_1 !== null ? person.person_id : null,
                credit_detail_2_type: credit_detail.account_detail_type_2,
                credit_detail_3_type: credit_detail.account_detail_type_3,
                sum: i.result || 0
              }
          )
        } else {
          row.sum += i.result || 0
        }
      })
      this.contribution.forEach(i => {
        const person = this.start_info.persons.find(p => p.person_id === i.person_id)
        const accounting = `${i.person_id}-${person.chart_of_account_id}-${chart_contribution}-${i.tax_id}`
        const chart_debit = this.charts.find(c => c.id === person.chart_of_account_id)
        const debit_detail = this.returnChartDetails(chart_debit)
        const chart_credit = this.charts.find(c => c.code === chart_contribution)
        const credit_detail = this.returnChartDetails(chart_credit)

        let debit_detail_1_value = null
        let debit_detail_2_value = null

        if (debit_detail.account_detail_type_1 !== null) {
          if (debit_detail.account_detail_type_1 === 'subdivision') {
            debit_detail_1_value = person.subdivision_id
          }
          if (debit_detail.account_detail_type_1 === 'cost_items') {
            debit_detail_1_value = cost_item_zarp
          }
        }
        if (debit_detail.account_detail_type_2 !== null) {
          if (debit_detail.account_detail_type_2 === 'cost_items') {
            debit_detail_2_value = cost_item_zarp
          }
        }

        const row = charge_accounting.find(a => a.key === accounting)
        if (!row) {
          charge_accounting.push(
              {
                key: accounting,
                person_id: i.person_id,
                debit_chart_of_account_id: chart_debit.id,
                debit_detail_1_type: debit_detail.account_detail_type_1,
                debit_detail_1_id: debit_detail_1_value,
                debit_detail_2_type: debit_detail.account_detail_type_2,
                debit_detail_2_id: debit_detail_2_value,
                debit_detail_3_type: debit_detail.account_detail_type_3,
                credit_chart_of_account_id: chart_credit.id,
                credit_detail_1_type: credit_detail.account_detail_type_1,
                credit_detail_1_id: credit_detail.account_detail_type_1 !== null ? i.tax_id : null,
                credit_detail_2_type: credit_detail.account_detail_type_2,
                credit_detail_3_type: credit_detail.account_detail_type_3,
                sum: this.round_number(i.result || 0, 2)
              }
          )
        } else {
          row.sum += this.round_number(i.result || 0, 2)
        }
      })
      this.tax_pdfo.forEach(i => {
        const person = this.start_info.persons.find(p => p.person_id === i.person_id)

        const chart_debit = this.charts.find(c => c.id === i.chart_of_account) || {}
        const debit_detail = this.returnChartDetails(chart_debit)

        const chart_credit = this.charts.find(c => c.code === chart_pdfo)
        const credit_detail = this.returnChartDetails(chart_credit)

        const accounting = `${i.person_id}-${chart_debit.id}-${chart_credit.id}`

        if (i.result_main !== 0) {
          const row = charge_accounting.find(a => a.key === accounting)
          if (!row) {
            charge_accounting.push(
                {
                  key: accounting,
                  person_id: i.person_id,
                  debit_chart_of_account_id: chart_debit.id,
                  debit_detail_1_type: debit_detail.account_detail_type_1,
                  debit_detail_1_id: person.person_id,
                  debit_detail_2_type: debit_detail.account_detail_type_2,
                  debit_detail_3_type: debit_detail.account_detail_type_3,
                  credit_chart_of_account_id: chart_credit.id,
                  credit_detail_1_type: credit_detail.account_detail_type_1,
                  credit_detail_2_type: credit_detail.account_detail_type_2,
                  credit_detail_3_type: credit_detail.account_detail_type_3,
                  sum: i.result_main || 0
                }
            )
          } else {
            row.sum += i.result_main || 0
          }
        }
      })
      this.tax_pdfo.forEach(i => {
        const person = this.start_info.persons.find(p => p.person_id === i.person_id)

        const chart_debit = this.charts.find(c => c.id === i.chart_of_account) || {}
        const debit_detail = this.returnChartDetails(chart_debit)

        const chart_credit = this.charts.find(c => c.code === chart_military)
        const credit_detail = this.returnChartDetails(chart_credit)

        const accounting = `${i.person_id}-${chart_debit.id}-${chart_credit.id}`

        if (i.result_military !== 0) {
          const row = charge_accounting.find(a => a.key === accounting)
          if (!row) {
            charge_accounting.push(
                {
                  key: accounting,
                  person_id: i.person_id,
                  debit_chart_of_account_id: chart_debit.id,
                  debit_detail_1_type: debit_detail.account_detail_type_1,
                  debit_detail_1_id: person.person_id,
                  debit_detail_2_type: debit_detail.account_detail_type_2,
                  debit_detail_3_type: debit_detail.account_detail_type_3,
                  credit_chart_of_account_id: chart_credit.id,
                  credit_detail_1_type: credit_detail.account_detail_type_1,
                  credit_detail_1_id: military_tax_id,
                  credit_detail_2_type: credit_detail.account_detail_type_2,
                  credit_detail_3_type: credit_detail.account_detail_type_3,
                  sum: i.result_military || 0
                }
            )
          } else {
            row.sum += i.result_military || 0
          }
        }

      })

      this.accounting = []

      charge_accounting.forEach(item => {
        this.accounting.push(
            {
              person_id: item.person_id,
              debit_chart_of_account_id: item.debit_chart_of_account_id,
              debit_detail_1_type: item.debit_detail_1_type,
              debit_detail_1_id: item.debit_detail_1_id,
              debit_detail_2_type: item.debit_detail_2_type,
              debit_detail_2_id: item.debit_detail_2_id,
              debit_detail_3_type: item.debit_detail_3_type,
              credit_chart_of_account_id: item.credit_chart_of_account_id,
              credit_detail_1_type: item.credit_detail_1_type,
              credit_detail_1_id: item.credit_detail_1_id,
              credit_detail_2_type: item.credit_detail_2_type,
              credit_detail_3_type: item.credit_detail_3_type,
              sum: this.round_number(item.sum, 2),
              row_num: this.getNewRowNum('accounting')
            }
        )
      })
    },
    buildAccountingByPersonId(payload) {
      const chart_contribution = '651'
      const chart_pdfo = '6411'
      const chart_military = '642'
      const chart_social_hospital = '378'
      const chart_debit_social = this.charts.find(c => c.code === chart_social_hospital)

      const military_tax_id = this.start_info.military_tax_id
      const cost_item_zarp = this.start_info.cost_item_zarp

      const charge_accounting = []
      this.charges.filter(item => item.person_id === payload).forEach(i => {
        const hospital_social = i.accrual_group === 'hospital_by_social'
        const person = this.start_info.persons.find(p => p.person_id === i.person_id)
        const accounting = `${i.person_id}-${hospital_social ? chart_debit_social.id : person.chart_of_account_id}-${i.chart_of_account}`
        const chart_debit = hospital_social
            ? chart_debit_social
            : this.charts.find(c => c.id === person.chart_of_account_id)
        const debit_detail = this.returnChartDetails(chart_debit)
        const chart_credit = this.charts.find(c => c.id === i.chart_of_account)
        const credit_detail = this.returnChartDetails(chart_credit)

        let debit_detail_1_value = null
        let debit_detail_2_value = null

        if (debit_detail.account_detail_type_1 !== null) {
          if (debit_detail.account_detail_type_1 === 'subdivision') {
            debit_detail_1_value = person.subdivision_id
          }
          if (debit_detail.account_detail_type_1 === 'cost_items') {
            debit_detail_1_value = cost_item_zarp
          }
        }

        if (debit_detail.account_detail_type_2 !== null) {
          if (debit_detail.account_detail_type_2 === 'cost_items') {
            debit_detail_2_value = cost_item_zarp
          }
        }

        const row = charge_accounting.find(a => a.key === accounting)
        if (!row) {
          charge_accounting.push(
              {
                key: accounting,
                person_id: i.person_id,
                debit_chart_of_account_id: chart_debit.id,
                debit_detail_1_type: debit_detail.account_detail_type_1,
                debit_detail_1_id: debit_detail_1_value,
                debit_detail_2_type: debit_detail.account_detail_type_2,
                debit_detail_2_id: debit_detail_2_value,
                debit_detail_3_type: debit_detail.account_detail_type_3,
                credit_chart_of_account_id: chart_credit.id,
                credit_detail_1_type: credit_detail.account_detail_type_1,
                credit_detail_1_id: credit_detail.account_detail_type_1 !== null ? person.person_id : null,
                credit_detail_2_type: credit_detail.account_detail_type_2,
                credit_detail_3_type: credit_detail.account_detail_type_3,
                sum: i.result || 0
              }
          )
        } else {
          row.sum += i.result || 0
        }
      })
      this.indexation.filter(item => item.person_id === payload).forEach(i => {
        const person = this.start_info.persons.find(p => p.person_id === i.person_id)
        const accounting = `${i.person_id}-${person.chart_of_account_id}-${i.chart_of_account}`
        const chart_debit = this.charts.find(c => c.id === person.chart_of_account_id)
        const debit_detail = this.returnChartDetails(chart_debit)
        const chart_credit = this.charts.find(c => c.id === i.chart_of_account)
        const credit_detail = this.returnChartDetails(chart_debit)

        let debit_detail_1_value = null
        let debit_detail_2_value = null

        if (debit_detail.account_detail_type_1 !== null) {
          if (debit_detail.account_detail_type_1 === 'subdivision') {
            debit_detail_1_value = person.subdivision_id
          }
          if (debit_detail.account_detail_type_1 === 'cost_items') {
            debit_detail_1_value = cost_item_zarp
          }
        }

        if (debit_detail.account_detail_type_2 !== null) {
          if (debit_detail.account_detail_type_2 === 'cost_items') {
            debit_detail_2_value = cost_item_zarp
          }
        }

        const row = charge_accounting.find(a => a.key === accounting)
        if (!row) {
          charge_accounting.push(
              {
                key: accounting,
                person_id: i.person_id,
                debit_chart_of_account_id: chart_debit.id,
                debit_detail_1_type: debit_detail.account_detail_type_1,
                debit_detail_1_id: debit_detail_1_value,
                debit_detail_2_type: debit_detail.account_detail_type_2,
                debit_detail_2_id: debit_detail_2_value,
                debit_detail_3_type: debit_detail.account_detail_type_3,
                credit_chart_of_account_id: chart_credit.id,
                credit_detail_1_type: credit_detail.account_detail_type_1,
                credit_detail_1_id: credit_detail.account_detail_type_1 !== null ? person.person_id : null,
                credit_detail_2_type: credit_detail.account_detail_type_2,
                credit_detail_3_type: credit_detail.account_detail_type_3,
                sum: i.result || 0
              }
          )
        } else {
          row.sum += i.result || 0
        }
      })
      this.contribution.filter(item => item.person_id === payload).forEach(i => {
        const person = this.start_info.persons.find(p => p.person_id === i.person_id)
        const accounting = `${i.person_id}-${person.chart_of_account_id}-${chart_contribution}-${i.tax_id}`
        const chart_debit = this.charts.find(c => c.id === person.chart_of_account_id)
        const debit_detail = this.returnChartDetails(chart_debit)
        const chart_credit = this.charts.find(c => c.code === chart_contribution)
        const credit_detail = this.returnChartDetails(chart_credit)

        let debit_detail_1_value = null
        let debit_detail_2_value = null

        if (debit_detail.account_detail_type_1 !== null) {
          if (debit_detail.account_detail_type_1 === 'subdivision') {
            debit_detail_1_value = person.subdivision_id
          }
          if (debit_detail.account_detail_type_1 === 'cost_items') {
            debit_detail_1_value = cost_item_zarp
          }
        }
        if (debit_detail.account_detail_type_2 !== null) {
          if (debit_detail.account_detail_type_2 === 'cost_items') {
            debit_detail_2_value = cost_item_zarp
          }
        }

        const row = charge_accounting.find(a => a.key === accounting)
        if (!row) {
          charge_accounting.push(
              {
                key: accounting,
                person_id: i.person_id,
                debit_chart_of_account_id: chart_debit.id,
                debit_detail_1_type: debit_detail.account_detail_type_1,
                debit_detail_1_id: debit_detail_1_value,
                debit_detail_2_type: debit_detail.account_detail_type_2,
                debit_detail_2_id: debit_detail_2_value,
                debit_detail_3_type: debit_detail.account_detail_type_3,
                credit_chart_of_account_id: chart_credit.id,
                credit_detail_1_type: credit_detail.account_detail_type_1,
                credit_detail_1_id: credit_detail.account_detail_type_1 !== null ? i.tax_id : null,
                credit_detail_2_type: credit_detail.account_detail_type_2,
                credit_detail_3_type: credit_detail.account_detail_type_3,
                sum: this.round_number(i.result || 0, 2)
              }
          )
        } else {
          row.sum += this.round_number(i.result || 0, 2)
        }
      })
      this.tax_pdfo.filter(item => item.person_id === payload).forEach(i => {
        const person = this.start_info.persons.find(p => p.person_id === i.person_id)

        const chart_debit = this.charts.find(c => c.id === i.chart_of_account) || {}
        const debit_detail = this.returnChartDetails(chart_debit)

        const chart_credit = this.charts.find(c => c.code === chart_pdfo)
        const credit_detail = this.returnChartDetails(chart_credit)

        const accounting = `${i.person_id}-${chart_debit.id}-${chart_credit.id}`

        if (i.result_main !== 0) {
          const row = charge_accounting.find(a => a.key === accounting)
          if (!row) {
            charge_accounting.push(
                {
                  key: accounting,
                  person_id: i.person_id,
                  debit_chart_of_account_id: chart_debit.id,
                  debit_detail_1_type: debit_detail.account_detail_type_1,
                  debit_detail_1_id: person.person_id,
                  debit_detail_2_type: debit_detail.account_detail_type_2,
                  debit_detail_3_type: debit_detail.account_detail_type_3,
                  credit_chart_of_account_id: chart_credit.id,
                  credit_detail_1_type: credit_detail.account_detail_type_1,
                  credit_detail_2_type: credit_detail.account_detail_type_2,
                  credit_detail_3_type: credit_detail.account_detail_type_3,
                  sum: i.result_main || 0
                }
            )
          } else {
            row.sum += i.result_main || 0
          }
        }
      })
      this.tax_pdfo.filter(item => item.person_id === payload).forEach(i => {
        const person = this.start_info.persons.find(p => p.person_id === i.person_id)

        const chart_debit = this.charts.find(c => c.id === i.chart_of_account) || {}
        const debit_detail = this.returnChartDetails(chart_debit)

        const chart_credit = this.charts.find(c => c.code === chart_military)
        const credit_detail = this.returnChartDetails(chart_credit)

        const accounting_661 = `${i.person_id}-${chart_debit.id}-${chart_credit.id}`

        if (i.result_military !== 0) {
          const row = charge_accounting.find(a => a.key === accounting_661)
          if (!row) {
            charge_accounting.push(
                {
                  key: accounting_661,
                  person_id: i.person_id,
                  debit_chart_of_account_id: chart_debit.id,
                  debit_detail_1_type: debit_detail.account_detail_type_1,
                  debit_detail_1_id: person.person_id,
                  debit_detail_2_type: debit_detail.account_detail_type_2,
                  debit_detail_3_type: debit_detail.account_detail_type_3,
                  credit_chart_of_account_id: chart_credit.id,
                  credit_detail_1_type: credit_detail.account_detail_type_1,
                  credit_detail_1_id: military_tax_id,
                  credit_detail_2_type: credit_detail.account_detail_type_2,
                  credit_detail_3_type: credit_detail.account_detail_type_3,
                  sum: i.result_military || 0
                }
            )
          } else {
            row.sum += i.result_military || 0
          }
        }

      })

      this.accounting.filter(item => item.person_id === payload).forEach(i => {
        this.accounting.splice(this.accounting.indexOf(i), 1)
      })

      charge_accounting.forEach(item => {
        this.accounting.push(
            {
              person_id: item.person_id,
              debit_chart_of_account_id: item.debit_chart_of_account_id,
              debit_detail_1_type: item.debit_detail_1_type,
              debit_detail_1_id: item.debit_detail_1_id,
              debit_detail_2_type: item.debit_detail_2_type,
              debit_detail_2_id: item.debit_detail_2_id,
              debit_detail_3_type: item.debit_detail_3_type,
              credit_chart_of_account_id: item.credit_chart_of_account_id,
              credit_detail_1_type: item.credit_detail_1_type,
              credit_detail_1_id: item.credit_detail_1_id,
              credit_detail_2_type: item.credit_detail_2_type,
              credit_detail_3_type: item.credit_detail_3_type,
              sum: this.round_number(item.sum, 2),
              row_num: this.getNewRowNum('accounting')
            }
        )
      })
    },
    getTotals() {
      this.footer_total.charge.result = this.charges.reduce((acc, item) => {
        return acc + item.result
      }, 0)
      this.footer_total.indexation.result = this.indexation.reduce((acc, item) => {
        return acc + item.result
      }, 0)
      this.footer_total.contribution = this.contribution.reduce((acc, item) => {
        acc.base += item.base || 0
        acc.base_vocation += item.base_vocation || 0
        acc.base_add += item.base_add || 0
        acc.result += item.result || 0
        acc.result_add += item.result_add || 0
        return acc
      }, {base: 0, base_vocation: 0, base_add: 0, result: 0, result_add: 0})
      this.footer_total.tax_pdfo = this.tax_pdfo.reduce((acc, item) => {
        acc.base += item.base || 0
        acc.result += item.result || 0
        acc.result_military += item.result_military || 0

        return acc
      }, {base: 0, result: 0, result_military: 0})
      this.footer_total.charge_result = this.charge_result.reduce((acc, item) => {
        acc.charge_result += item.charge_result || 0
        acc.contribution += item.contribution || 0
        acc.tax += item.tax || 0
        acc.to_pay += item.to_pay || 0

        return acc
      }, {charge_result: 0, contribution: 0, tax: 0, to_pay: 0})
    },
    getIndexationIndex(from) {
      let value = 1
      let index_result = 0
      const idx_arr = []
      this.start_info.inflation_indexes.filter(i => i.month >= from && i.month < this.month).map(idx => {
        value = value * (idx.value / 100)

        if (index_result === 0) {
          index_result = idx.value / 100
        } else {
          index_result = index_result * idx.value / 100
        }

        if (index_result >= 1.03) {
          idx_arr.push(index_result)
          index_result = 0
        }

      })

      index_result = 1

      idx_arr.forEach(idx => {
        index_result = index_result * idx
      })

      return this.round_number(index_result, 3)
    },
    getTaxTariff(tax_id, month) {
      const month_int = +month.replaceAll('-', '')
      const sorted = this.start_info.tax_tariff.filter(item => item.tax_id === tax_id && item.month_int <= month_int)
          .map(i => Object.assign({}, i)).sort((a, b) => a.month_int - b.month_int)

      return sorted.length ? sorted[sorted.length - 1].tariff || 0 : 0
    },
    getMinSalary(month) {
      const month_int = +month.replaceAll('-', '')
      const sorted = this.start_info.min_amounts.filter(item => item.month_int <= month_int)
          .map(i => Object.assign({}, i))
          .sort((a, b) => a.month_int - b.month_int)
      return sorted.length ? sorted[sorted.length - 1].min_salary || 0 : 0
    },
    getCostOfLife(month) {
      const month_int = +month.replaceAll('-', '')
      const sorted = this.start_info.min_amounts.filter(item => item.month_int <= month_int)
          .map(i => Object.assign({}, i)).sort((a, b) => a.month_int - b.month_int)

      return sorted.length ? sorted[sorted.length - 1].cost_of_living || 0 : 0
    },
    getHireAndReleaseDate(payload) {
      if (!payload) return ''
      if (payload.person_type !== 'full_time') return ''

      const hire_date = formatDate(payload.hire_date)
      const release_date = payload.release_date ? formatDate(payload.release_date) : ''

      if (hire_date && release_date) {
        return `${hire_date} - ${release_date}`
      } else {
        return hire_date
      }
    },
    getPersonType(payload, payload_add) {
      if (!payload) return ''
      if (payload === 'full_time') {
        if (payload_add === 'main_work_place') {
          return 'осн.'
        }
        if (payload_add === 'combination') {
          return 'совм.'
        }
        return 'осн.'
      }
      if (payload === 'by_contract') {
        return 'дог.'
      }
    },
    onPersonChange(payload) {
      const value = (payload || {}).value || (payload || {}).id || null
      if (value) {
        const person = this.persons.find(item => item.person_id === value)
        if (!person) {
          const cripple_category = (payload || {}).additional_field_3 || (payload || {}).cripple_category
          this.persons.push(
              {
                person_id: value,
                full_name: (payload || {}).text || (payload || {}).full_name,
                person_type: (payload || {}).type || (payload || {}).person_type,
                cripple: cripple_category !== null,
                hire_date: (payload || {}).additional_field_1 || (payload || {}).hire_date,
                release_date: (payload || {}).additional_field_2 || (payload || {}).release_date,
                cripple_category: cripple_category,
                row_num: this.getNewRowNum('persons')
              }
          )
        }
      }
    },
    onContributionTaxChange(payload) {
      const value = (payload || {}).value || (payload || {}).id || null
      if (value) {
        const row = this.contribution.find(i => i.row_num === payload.row_num)
        if (row) {
          row.tariff = this.getTaxTariff(value, row.month)
        }
      }
    },
    onChargeCharge(payload) {
      const value = (payload || {}).value || (payload || {}).id || null
      const row_num = (payload || {}).row_num || null
      if (value) {
        const current_row = this.charges.find(item => item.row_num === row_num)
        const accrual = this.start_info.accruals.find(item => item.id === value)
        if (accrual) {
          current_row.accrual_count_type = accrual.accrual_count_type
          current_row.accrual_id = value
          current_row.accrual_type = accrual.accrual_type
          current_row.accrual_group = accrual.accrual_group
          current_row.chart_of_account = accrual.chart_of_account
          current_row.income_type_pix_id = accrual.income_type_pix_id
          current_row.order = current_row.accrual_type === 'main' ? -1 : accrual.account_order
          current_row.tax_group = accrual.tax_group
        }
      }
    },
    addCharge() {
      this.charges.push(
          {
            person_id: this.selected_person,
            accrual_count_type: null,
            accrual_id: null,
            accrual_type: null,
            accrual_group: null,
            chart_of_account: null,
            date_end: endOfMonth(this.month),
            date_start: this.month,
            month: this.month,
            days: 0,
            days_n: 0,
            hours: 0,
            hours_n: 0,
            income_type_pix_id: 0,
            order: null,
            result: 0,
            row_num: this.getNewRowNum('charges'),
            tariff: 0,
            tax_group: null,

          }
      )
    },
    addAccounting() {
      this.accounting.push(
          {
            person_id: this.selected_person,
            debit_chart_of_account_id: null,
            debit_detail_1_type: null,
            debit_detail_1_id: null,
            debit_detail_2_type: null,
            debit_detail_2_id: null,
            debit_detail_3_type: null,
            debit_detail_3_id: null,
            credit_chart_of_account_id: null,
            credit_detail_1_type: null,
            credit_detail_1_id: null,
            credit_detail_2_type: null,
            credit_detail_2_id: null,
            credit_detail_3_type: null,
            credit_detail_3_id: null,
            sum: 0,
            row_num: this.getNewRowNum('accounting'),
          }
      )
    },
    addIndexation() {
      const accrual_idx = this.start_info.accruals.find(i => i.system_name === 'salary_indexing') || {}
      if (accrual_idx) {
        this.indexation.push(
            {
              person_id: this.selected_person,
              base: 0,
              base_month: null,
              accrual_id: accrual_idx.id,
              index: 0,
              result: 0,
              accrual_group: accrual_idx.accrual_group,
              chart_of_account: accrual_idx.chart_of_account,
              tax_group: accrual_idx.tax_group,
              income_type_pix_id: accrual_idx.income_type_pix_id,
              month: this.month,
              row_num: this.getNewRowNum('indexation')
            }
        )
      }
    },
    addContribution() {
      this.contribution.push(
          {
            base: 0,
            base_add: 0,
            month: this.month,
            person_id: this.selected_person,
            accrual_group: null,
            result: 0,
            result_add: 0,
            row_num: this.getNewRowNum('contribution'),
            tariff: 0,
            tax_id: null
          }
      )
    },
    addPDFO() {
      const pdfo_tariff = this.getTaxTariff(this.start_info.pdfo_tax_id, this.month)
      const military_tariff = this.getTaxTariff(this.start_info.military_tax_id, this.month)

      this.tax_pdfo.push(
          {
            base: 0,
            base_main: 0,
            base_hospital: 0,
            income_type_pix_id: null,
            military_tariff: military_tariff,
            month: this.month,
            pdfo_tariff: pdfo_tariff,
            person_id: this.selected_person,
            privilege_count: 0,
            privilege_type: null,
            result: 0,
            result_main: 0,
            result_military: 0,
            accrual_group: null,
            row_num: this.getNewRowNum('tax_pdfo')
          }
      )
    },
    getCalcTypeName(payload) {
      const list = {
        'by_days': 'Оклад',
        'by_hours': 'Оклад',
        'by_average': 'Середнє',
        'by_fixed': 'Фікс. сума',
        'by_percent': 'Процент',
        'by_day_tariff': 'Денний тариф',
        'by_hour_tariff': 'Годинний тариф',
        'not_calc': 'Автоматично'
      }
      if (!payload) return 'Не визначено'
      return list[payload] || 'Не визначено'
    },
    getNewRowNum(table) {
      const max_row_item = maxBy(this[table], 'row_num')

      let max_row_num = 1
      if (max_row_item) {
        max_row_num = max_row_item.row_num + 1
      }
      return max_row_num
    },
    roundUp(x) {
      return x % 10 === 0 ? x : x + 10 - x % 10
    },
    onChargeChange(payload) {
      const charge_obj = this.charges.find(i => i.row_num === payload.row_num) || {}
      if (charge_obj.person_id) {
        this.$nextTick(() => {
          this.calcCharge(charge_obj)
          this.calcContribution(charge_obj.person_id)
          this.calcPDFO(charge_obj.person_id)
          this.calcResult(charge_obj.person_id)
          this.getTotals()
          this.buildAccountingByPersonId(charge_obj.person_id)
        })
      }
    },
    onChargeResultChange(payload) {
      const charge_obj = this.charges.find(i => i.row_num === payload.row_num) || {}
      if (charge_obj.person_id) {
        this.$nextTick(() => {
          if (charge_obj.accrual_type === 'main') {
            this.calcSubCharge(charge_obj.person_id)
          }
          this.calcContribution(charge_obj.person_id)
          this.calcPDFO(charge_obj.person_id)
          this.calcResult(charge_obj.person_id)
          this.getTotals()
          this.buildAccountingByPersonId(charge_obj.person_id)
        })
      }
    },
    onIndexationChange(payload) {
      const charge_obj = this.indexation.find(i => i.row_num === payload.row_num) || {}
      if (charge_obj.person_id) {
        charge_obj.result = this.round_number(charge_obj.base * (charge_obj.index >= 1 ? charge_obj.index - 1 : 0), 2)
        this.$nextTick(() => {
          this.calcContribution(charge_obj.person_id)
          this.calcPDFO(charge_obj.person_id)
          this.calcResult(charge_obj.person_id)
          this.getTotals()
          this.buildAccountingByPersonId(charge_obj.person_id)
        })
      }
    },
    onIndexationResultChange(payload) {
      const charge_obj = this.indexation.find(i => i.row_num === payload.row_num) || {}
      if (charge_obj.person_id) {
        this.$nextTick(() => {
          this.calcContribution(charge_obj.person_id)
          this.calcPDFO(charge_obj.person_id)
          this.calcResult(charge_obj.person_id)
          this.getTotals()
          this.buildAccountingByPersonId(charge_obj.person_id)
        })
      }
    },
    onContributionChange(payload) {
      const charge_obj = this.contribution.find(i => i.row_num === payload.row_num) || {}
      if (charge_obj.person_id) {
        charge_obj.result = this.round_number(charge_obj.base * charge_obj.tariff / 100, 2)
        this.$nextTick(() => {
          this.calcPDFO(charge_obj.person_id)
          this.calcResult(charge_obj.person_id)
          this.getTotals()
          this.buildAccountingByPersonId(charge_obj.person_id)
        })
      }
    },
    onContributionResultChange(payload) {
      const charge_obj = this.contribution.find(i => i.row_num === payload.row_num) || {}
      if (charge_obj.person_id) {
        this.$nextTick(() => {
          this.calcPDFO(charge_obj.person_id)
          this.calcResult(charge_obj.person_id)
          this.getTotals()
          this.buildAccountingByPersonId(charge_obj.person_id)
        })
      }
    },
    onPdfoChange(payload) {
      const charge_obj = this.tax_pdfo.find(i => i.row_num === payload.row_num) || {}
      if (charge_obj.person_id) {
        this.$nextTick(() => {
          this.calcPDFO(charge_obj.person_id)
          this.calcResult(charge_obj.person_id)
          this.getTotals()
          this.buildAccountingByPersonId(charge_obj.person_id)
        })
      }
    },
    onPdfoResultChange(payload) {
      const charge_obj = this.tax_pdfo.find(i => i.row_num === payload.row_num) || {}
      if (charge_obj.person_id) {
        this.$nextTick(() => {
          this.calcResult(charge_obj.person_id)
          this.getTotals()
          this.buildAccountingByPersonId(charge_obj.person_id)
        })
      }
    },
    deleteCharge(payload) {
      const person_id = payload.person_id
      this.charges.splice(this.charges.indexOf(payload), 1)
      this.$nextTick(() => {
        this.calcSubCharge(person_id)
        this.calcIndexation(person_id)
        this.calcContribution(person_id)
        this.calcPDFO(person_id)
        this.calcResult(person_id)
        this.getTotals()
        this.buildAccountingByPersonId(person_id)
      })
    },
    deleteIndexation(payload) {
      const person_id = payload.person_id
      this.indexation.splice(this.indexation.indexOf(payload), 1)
      this.$nextTick(() => {
        this.calcContribution(person_id)
        this.calcPDFO(person_id)
        this.calcResult(person_id)
        this.getTotals()
        this.buildAccountingByPersonId(person_id)
      })
    },
    deleteContribution(payload) {
      const person_id = payload.person_id
      this.contribution.splice(this.contribution.indexOf(payload), 1)
      this.$nextTick(() => {
        this.calcPDFO(person_id)
        this.calcResult(person_id)
        this.getTotals()
        this.buildAccountingByPersonId(person_id)
      })
    },
    deletePDFO(payload) {
      const person_id = payload.person_id
      this.tax_pdfo.splice(this.tax_pdfo.indexOf(payload), 1)
      this.$nextTick(() => {
        this.calcResult(person_id)
        this.getTotals()
        this.buildAccountingByPersonId(person_id)
      })
    },
    deleteAccounting(payload) {
      this.accounting.splice(this.accounting.indexOf(payload), 1)
    },
    getSocialPrivilegeCalcData(privilege_type, max_salary, base, children = 0) {
      const privilege_data = this.social_privilege_data[privilege_type] || {base_counter: 0, children: false}

      let max_salary_result = (max_salary * (privilege_data.children ? children : 1)) || 0
      let base_result = (base * privilege_data.base_counter * (privilege_data.children ? children : 1)) || 0

      return {
        salary: max_salary_result,
        base: base_result
      }
    },
    calcSubCharge(person_id, start_order = 0) {
      let orders = [0, 1, 2]

      orders = orders.map(item => {
        if (item >= start_order) {
          return item
        }
      })

      orders.forEach(order => {
        const rows = this.charges.filter(item => item.person_id === person_id && item.accrual_type === 'secondary' && item.order === order)

        if (order === 2) {
          this.calcIndexation(person_id)
        }

        if (rows.length) {
          // this.charges.forEach(item => {
          //   if (item.person_id === person_id) {
          //     console.log(item)
          //   }
          // })
          const base_sum = this.charges.filter(
              item => item.person_id === person_id && item.order < order && item.accrual_group === 'main'
          ).reduce((acc, r_item) => acc + r_item.result, 0)
          rows.forEach(row => {
            if (row.accrual_count_type === 'by_percent') {
              row.result = this.round_number(row.tariff * base_sum / 100, 2)
            }
          })
        }
      })
    },
    calcIndexation(person_id) {
      const accrual_count_types = [
        'by_days',
        'by_day_tariff',
        'by_hours',
        'by_hour_tariff',
        'by_average'
      ]
      let base = 0
      let result = 0

      let base_month = (this.start_info.indexation_base.find(i => i.person_id === person_id) || {}).base_month
      let idx = base_month ? this.getIndexationIndex(base_month) : 1
      let min_salary = this.getCostOfLife(this.month)

      this.charges.filter(i => i.person_id === person_id).map(charge => {
        if (accrual_count_types.includes(charge.accrual_count_type)) {
          const accrual = (this.start_info.accruals.find(a => a.id === charge.accrual_id) || {}).is_vocation || false
          if (!accrual && charge.accrual_type === 'main') {
            base += charge.result
          }
        }
      })

      result = this.round_number((base <= min_salary ? base : min_salary) * (idx - 1), 2)

      if (idx < 1.03) {
        result = 0
      }

      const idx_row = this.indexation.find(i => i.person_id === person_id)

      if (idx_row) {
        if (result) {
          idx_row.base = this.round_number(base <= min_salary ? base : min_salary, 2)
          idx_row.index = this.round_number(idx, 6)
          idx_row.result = result
        } else {
          this.indexation.splice(this.indexation.indexOf(idx_row), 1)
        }
      } else {
        if (result) {
          const accrual_idx = this.start_info.accruals.find(i => i.system_name === 'salary_indexing') || {}
          this.indexation.push(
              {
                person_id: person_id,
                base: this.round_number(base <= min_salary ? base : min_salary, 2),
                base_month: base_month,
                accrual_id: accrual_idx.id,
                index: this.round_number(idx, 6),
                result: result,
                chart_of_account: accrual_idx.chart_of_account,
                accrual_group: accrual_idx.accrual_group,
                tax_group: accrual_idx.tax_group,
                income_type_pix_id: accrual_idx.income_type_pix_id,
                month: this.month,
                row_num: this.getNewRowNum('indexation')
              }
          )
        }
      }
    },
    calcContribution(person_id) {
      const taxes = []
      const cripple = (this.persons.find(item => item.person_id === person_id) || {}).cripple || false
      const person_obj = this.persons.find(item => item.person_id === person_id)
      let min_salary = this.getMinSalary(this.month)
      let base_ = 0
      let accrual_group_max_base = 0
      let accrual_group_max = null

      this.charges.filter(item => item.person_id === person_id).forEach(charge => {
        if (charge.tax_group && charge.accrual_group) {
          const month = beginOfMonth(charge.date_start || this.month)
          const t_find = taxes.find(t => t.tax_group === charge.tax_group && t.month === month
              && t.accrual_group === charge.accrual_group)
          if (t_find) {
            t_find.base += this.round_number((charge.result || 0), 2)
          } else {
            taxes.push(
                {
                  tax_group: charge.tax_group,
                  month: month,
                  base: this.round_number((charge.result || 0), 2),
                  base_add: 0,
                  present: false,
                  accrual_group: charge.accrual_group
                }
            )
          }
        }
      })

      const idx_row = this.indexation.find(item => item.person_id === person_id)
      if (idx_row) {
        const t_find = taxes.find(t => t.tax_group === idx_row.tax_group && t.month === idx_row.month && t.accrual_group === idx_row.accrual_group)
        if (t_find) {
          t_find.base += this.round_number((idx_row.result || 0), 2)

        } else {
          const t_find_ = taxes.find(t => t.tax_group === idx_row.tax_group && t.month === idx_row.month)

          if (t_find_) {
            t_find_.base += this.round_number((idx_row.result || 0), 2)
          } else {
            taxes.push(
                {
                  tax_group: idx_row.tax_group,
                  month: idx_row.month,
                  base: this.round_number((idx_row.result || 0), 2),
                  present: false,
                  accrual_group: idx_row.accrual_group
                }
            )
          }
        }
      }

      taxes.forEach(item => {
        const month = beginOfMonth(item.date_start || this.month)
        const tax_group_tax = this.start_info.esv_taxes.find(tax => tax.tax_group === item.tax_group && tax.invalid === cripple)
        if (tax_group_tax) {
          item.tax_id = tax_group_tax.id
          item.tariff = this.getTaxTariff(tax_group_tax.id, item.month)
        }

        if (item.base >= accrual_group_max_base) {
          accrual_group_max_base = item.base
          accrual_group_max = item.accrual_group
        }

        if (!cripple && person_obj.hire_date !== this.month
            && person_obj.release_date !== endOfMonth(this.month)
            && person_obj.full_time_type !== 'combination'
            && item.month === month
            && item.base > 0) {
          base_ += this.round_number(item.base, 2)
        }
      })

      this.contribution.filter(item => item.person_id === person_id).forEach(item => {
        const new_tax_row = taxes.find(t => t.tax_id === item.tax_id
                                            && t.month === item.month && t.accrual_group === item.accrual_group)
        if (new_tax_row) {
          new_tax_row.present = true
          item.base_add = 0
          item.result_add = 0

          if (base_ < min_salary
              && base_ > 0 && new_tax_row.accrual_group === accrual_group_max) {
            item.base_add = this.round_number(min_salary - base_, 2)
            item.result_add = this.round_number((min_salary - base_) * new_tax_row.tariff / 100, 2)
          } else {
            item.base_add = 0
            item.result_add = 0
          }

          item.base = new_tax_row.base + item.base_add
          item.result = this.round_number(new_tax_row.base * new_tax_row.tariff / 100 + item.result_add, 2) || 0


        } else {
          this.contribution.splice(this.contribution.indexOf(item), 1)
        }
      })

      taxes.filter(t => !t.present).forEach(row => {
        this.contribution.push(
            {
              person_id,
              month: row.month,
              tax_id: row.tax_id,
              base: row.base,
              tariff: row.tariff,
              result: this.round_number(row.base * row.tariff / 100, 2) || 0,
              row_num: this.getNewRowNum('contribution'),
              accrual_group: row.accrual_group
            }
        )
      })
    },
    calcPDFO(person_id) {
      const taxes = []
      const pix_101 = this.start_info?.pix_101
      let base_101 = 0
      let base_101_max = 0
      let base_101_accrual_group = null

      this.charges.filter(charge => charge.person_id === person_id).forEach(item => {
        const month = beginOfMonth(item.date_start || this.month)
        const pdfo_tariff = this.getTaxTariff(this.start_info.pdfo_tax_id, month)
        const military_tariff = this.getTaxTariff(this.start_info.military_tax_id, month)
        const accrual = this.start_info.accruals.find(a => a.id === item.accrual_id)
        const is_hospital = accrual !== null ? accrual.is_hospital : false

        const tax_row = taxes.find(t => t.month === month
                                   && t.income_type_pix_id === item.income_type_pix_id
                                   && t.chart_of_account === item.chart_of_account
                                   && t.accrual_group === item.accrual_group)

        if (pix_101 === item.income_type_pix_id && this.month === item.month) {
          base_101 += (item.result || 0)

          if ((item.result || 0) >= base_101_max) {
            base_101_max = (item.result || 0)
            base_101_accrual_group = item.accrual_group
          }
        }

        if (tax_row) {
          tax_row.base += (item.result || 0)
          tax_row.base_hospital += is_hospital ? (item.result || 0) : 0
          tax_row.base_main += is_hospital ? 0 : (item.result || 0)
        } else {
          taxes.push(
              {
                income_type_pix_id: item.income_type_pix_id,
                month: month,
                base: (item.result || 0),
                base_hospital: is_hospital ? (item.result || 0) : 0,
                base_main: is_hospital ? 0 : (item.result || 0),
                present: false,
                pdfo_tariff: pdfo_tariff,
                military_tariff: military_tariff,
                accrual_group: item.accrual_group,
                chart_of_account: item.chart_of_account
              }
          )
        }
      })

      const idx_row = this.indexation.find(item => item.person_id === person_id)
      if (idx_row) {
        const accrual_indexation = (this.start_info.accruals.find(i => i.id === idx_row.accrual_id) || {}).chart_of_account

        const tax_row = taxes.find(t => t.month === idx_row.month
                                   && t.income_type_pix_id === idx_row.income_type_pix_id
                                   && t.chart_of_account === accrual_indexation
                                   && t.accrual_group === idx_row.accrual_group)

        const pdfo_tariff = this.getTaxTariff(this.start_info.pdfo_tax_id, idx_row.month)
        const military_tariff = this.getTaxTariff(this.start_info.military_tax_id, idx_row.month)

        if (pix_101 === idx_row.income_type_pix_id
            && this.month === idx_row.month) {
          base_101 += (idx_row.result || 0)

          if ((idx_row.result || 0) >= base_101_max) {
            base_101_max = (idx_row.result || 0)
            base_101_accrual_group = idx_row.accrual_group
          }
        }

        if (tax_row) {
          tax_row.base += (idx_row.result || 0)
          tax_row.base_main += (idx_row.result || 0)
          tax_row.base_hospital += 0
        } else {
          taxes.push(
              {
                income_type_pix_id: idx_row.income_type_pix_id,
                month: idx_row.month,
                base: (idx_row.result || 0),
                base_main: (idx_row.result || 0),
                base_hospital: 0,
                present: false,
                pdfo_tariff: pdfo_tariff,
                military_tariff: military_tariff,
                accrual_group: idx_row.accrual_group || null,
                chart_of_account: accrual_indexation || null
              }
          )
        }
      }

      this.tax_pdfo.filter(tax => tax.person_id === person_id).forEach(item => {
        const tax_row = taxes.find(t => t.month === item.month
                                   && t.income_type_pix_id === item.income_type_pix_id
                                   && t.chart_of_account === item.chart_of_account
                                   && t.accrual_group === item.accrual_group)

        if (tax_row) {
          tax_row.present = true
          if (item.privilege_type) {
            const min_live = this.getCostOfLife(item.month)
            const pdfo_max_salary = this.roundUp(min_live * 1.4)
            const pdfo_privilege_base = min_live / 2

            const privilege_data = this.getSocialPrivilegeCalcData(
                item.privilege_type, pdfo_max_salary, pdfo_privilege_base, item.privilege_count || 0
            )

            if (base_101_accrual_group === item.accrual_group
                && pix_101 === item.income_type_pix_id
                && this.month === item.month
                && base_101 <= privilege_data.salary
            ) {
              const pre = base_101 - privilege_data.base >= 0 ? base_101 - privilege_data.base : 0
              item.base = tax_row.base
              item.result = this.round_number(pre * tax_row.pdfo_tariff / 100, 2)
              item.result_main = this.round_number(pre * tax_row.pdfo_tariff / 100, 2)
              item.result_military = this.round_number(item.base * tax_row.military_tariff / 100, 2)
            } else {
              item.base = tax_row.base
              item.result = this.round_number(tax_row.base * tax_row.pdfo_tariff / 100, 2)
              item.result_main = this.round_number(tax_row.base * tax_row.pdfo_tariff / 100, 2)
              item.result_military = this.round_number(tax_row.base * tax_row.military_tariff / 100, 2)
            }

          } else {
            item.base = tax_row.base
            item.result = this.round_number(tax_row.base * tax_row.pdfo_tariff / 100, 2)
            item.result_main = this.round_number(tax_row.base * tax_row.pdfo_tariff / 100, 2)
            item.result_military = this.round_number(tax_row.base * tax_row.military_tariff / 100, 2)
          }
        } else {
          this.tax_pdfo.splice(this.tax_pdfo.indexOf(item), 1)
        }
      })

      taxes.filter(t => !t.present).forEach(row => {
        // console.log(row)
        this.tax_pdfo.push(
            {
              person_id,
              base: row.base,
              income_type_pix_id: row.income_type_pix_id,
              month: row.month,
              pdfo_tariff: row.pdfo_tariff,
              privilege_count: 0,
              privilege_type: null,
              result: this.round_number(row.base * row.pdfo_tariff / 100, 2),
              result_main: this.round_number(row.base * row.pdfo_tariff / 100, 2),
              result_military: this.round_number(row.base * row.military_tariff / 100, 2),
              row_num: this.getNewRowNum('tax_pdfo'),
              accrual_group: row.accrual_group || null,
              chart_of_account: row.chart_of_account || null
            }
        )
      })
    },
    calcResultAll() {
      this.persons.forEach(item => {
        this.calcResult(item.person_id)
      })
    },
    calcResult(person_id) {
      const charge_result = this.charges.filter(c => c.person_id === person_id).reduce((acc, item) => {
        return acc + item.result
      }, 0)
      const indexation_result = this.indexation.filter(c => c.person_id === person_id).reduce((acc, item) => {
        return acc + item.result
      }, 0)
      const contribution_result = this.contribution.filter(c => c.person_id === person_id).reduce((acc, item) => {
        return acc + item.result
      }, 0)
      const pdfo_result = this.tax_pdfo.filter(c => c.person_id === person_id).reduce((acc, item) => {
        return acc + item.result
      }, 0)
      const military_result = this.tax_pdfo.filter(c => c.person_id === person_id).reduce((acc, item) => {
        return acc + item.result_military
      }, 0)

      const row = this.charge_result.find(item => item.person_id === person_id)
      if (row) {
        row.charge_result = this.round_number(charge_result + indexation_result, 2)
        row.contribution = this.round_number(contribution_result, 2)
        row.tax = this.round_number(pdfo_result + military_result, 2)
        row.to_pay = this.round_number(charge_result - row.tax, 2)
      } else {
        this.charge_result.push(
            {
              row_num: this.getNewRowNum('charge_result'),
              person_id: person_id,
              charge_result: this.round_number(charge_result + indexation_result, 2),
              contribution: this.round_number(contribution_result, 2),
              tax: this.round_number(pdfo_result + military_result, 2),
              to_pay: this.round_number(charge_result - (pdfo_result + military_result), 2)
            }
        )
      }
    },
    round_number(value, precision) {
      return Math.round((value + Number.EPSILON) * Math.pow(10, precision)) / Math.pow(10, precision)
    },
    calcCharge(charge_row) {
      if (!charge_row) return
      if (charge_row.accrual_type === 'main') {
        if (charge_row.accrual_count_type === 'by_days') {
          charge_row.result = charge_row.tariff / charge_row.days_n * charge_row.days
          charge_row.result = Math.round((charge_row.result + Number.EPSILON) * 100) / 100
        }

        if (charge_row.accrual_count_type === 'by_hours') {
          charge_row.result = charge_row.tariff / charge_row.hours_n * charge_row.hours
          charge_row.result = Math.round((charge_row.result + Number.EPSILON) * 100) / 100
        }

        if (charge_row.accrual_count_type === 'by_fixed') {
          charge_row.result = charge_row.tariff
        }

        if (charge_row.accrual_count_type === 'by_day_tariff') {
          charge_row.result = charge_row.tariff * charge_row.days
        }

        if (charge_row.accrual_count_type === 'by_hour_tariff') {
          charge_row.result = charge_row.tariff * charge_row.hours
        }

        if (charge_row.accrual_count_type === 'by_average') {
          charge_row.result = charge_row.tariff * charge_row.days
        }
      } else {
        if (charge_row.accrual_count_type === 'by_fixed') {
          charge_row.result = charge_row.tariff
        }
      }
      this.calcSubCharge(charge_row.person_id)
    },
    getStartInfo(func=null) {
      if (this.date && this.month) {
        if (!this.charts || !this.charts.length) {
          this.getChartsOfAccounts()
        }
        salaryChargeAPI.start_info({month_start: this.month, month_end: this.month})
            .then(response => response.data)
            .then(data => {
              this.start_info = Object.assign({}, data)
              if (func) {
                func()
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      }
    },
    fillDocumentByAll() {
      // this.getStartInfo()
      // salaryChargeAPI.person_indexation({month: this.month})
      if (this.date && this.month) {
        this.persons = []
        this.charges = []
        this.indexation = []
        this.contribution = []
        this.tax_pdfo = []
        this.charge_result = []
        this.accounting = []

        const payload = {
          month: this.month,
          subdivision_id: this.subdivision_id,
          document_id: this.itemId,
          is_advance: this.is_advance,
          date: this.date
        }

        salaryChargeAPI.fill_by_all(payload)
            .then(response => response.data)
            .then(data => {
              this.persons = data.persons.map((i, idx) => {
                return Object.assign({}, i, {row_num: idx + 1})
              })
              this.charges = data.charge.map((i, idx) => {
                return Object.assign({}, i, {row_num: idx + 1})
              })
              if (!this.is_advance) {
                this.indexation = data.indexing.map((i, idx) => {
                  return Object.assign({}, i, {row_num: idx + 1})
                })
                this.contribution = data.esv.map((i, idx) => {
                  return Object.assign({}, i, {row_num: idx + 1})
                })
                this.tax_pdfo = data.pdfo.map((i, idx) => {
                  return Object.assign({}, i, {row_num: idx + 1})
                })
                this.calcResultAll()
                this.buildAccounting()
              }
              this.getTotals()
              if (!this.selected_person && this.persons.length) {
                this.selected_person = this.persons[0].person_id
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      }
    },
    fillByAll() {
      if (!this.persons.length) {
        this.getStartInfo(this.fillDocumentByAll())
      } else {
        const payload = {
          text: `Документ вже заповнений. Виконати перезаповнення`,
          accept_button: true,
          id: "charge_refill_id",
          icon: 'mdi-text-box-check-outline',
          icon_color: 'secondary'
        }
        this.$store.dispatch(QUESTION_SHOW, payload)
      }
    },
    clearPersonData(payload) {
      let local_data = payload || {}

      this.tab = 0
      this.selected_person = null
      this.date = local_data.date || null
      this.number = local_data.number || null
      this.month = local_data.month || null
      this.subdivision_id = local_data.subdivision_id || null
      this.currency_id = local_data.currency_id || null
      this.is_advance = local_data.is_advance || false
      this.persons = []
      this.charges = []
      this.indexation = []
      this.contribution = []
      this.charge_result = []
      this.accounting = []
      this.tax_pdfo = []
      this.itemId = payload.id || null
    },
    validateForm(only_bool = false) {
      const required_items = {
        'date': 'Дата',
        'date_start': 'Дата початку',
      }

      let form_valid = true
      let errors = []

      Object.keys(required_items).forEach(key => {
        if (!this[key]) {
          form_valid = false
          if (!only_bool) {
            errors.push(required_items[key])
          }
        }
      })

      if (only_bool) return form_valid
      let text = 'Виправте помилки: \n' + errors.join(', ') + ' - мають бути заповнені. \n'
      return {
        valid: form_valid,
        text: text
      }

    },
    checkDocumentTable(filters, table_name, table) {
      const table_charge_error = []
      const items = this[table].filter(item => {
        let r = false
        Object.keys(filters).forEach(key => {
          if (item[key] === undefined || item[key] === null || item[key] === 0) {
            r = true
          }
        })
        return r
      })

      items.forEach(item => {
        const idx = this[table].indexOf(item)
        const row_key = `row_${idx}`

        Object.keys(filters).forEach(key => {
          const filter_row = filters[key]
          let skip = false

          if (filter_row.condition) {
            if (item[filter_row.condition] !== null && item[filter_row.condition] !== undefined || item[filter_row.condition] !== 0) {
              skip = true
            }
          }

          if (item[key] !== null && item[key] !== undefined && item[key] !== 0) {
            skip = true
          }

          if (!skip) {
            if (!table_charge_error[row_key]) {
              table_charge_error[row_key] = {row_num: idx + 1, text: filters[key].text}
            } else {
              table_charge_error[row_key].text += `// ${filters[key].text}`
            }
          }
        })
      })

      Object.keys(table_charge_error).forEach(key => {
        this.document_state.errors.push(
            {table: table_name, row_num: table_charge_error[key].row_num, text: table_charge_error[key].text}
        )
      })
    },
    checkDocument() {
      this.document_state.show = !this.document_state.show
      this.document_state.errors = []

      const header = {
        'date': {id: 'date', text: 'Не заповнена дата документа'},
        'month': {id: 'month', text: 'Не заповнений місяць нарахування заробітної плати'}
      }

      const table_charge = {
        'person_id': {table: 'Нарахування', text: 'Не заповнений працівник'},
        'accrual_id': {table: 'Нарахування', text: 'Не заповнений вид нарахування'},
      }

      const table_indexation = {
        'person_id': {table: 'Нарахування', text: 'Не заповнений працівник'},
        'base_month': {table: 'Нарахування', text: 'Не заповнений базовий місяць'},
      }

      const table_contribution = {
        'person_id': {table: 'Нарахування', text: 'Не заповнений працівник'},
        'tax_id': {table: 'Нарахування', text: 'Не заповнений вид внеску'},
        'tariff': {table: 'Нарахування', text: 'Не заповнений процент внеску'},
        'month': {table: 'Нарахування', text: 'Не заповнений місяць внеску'},
      }


      const table_tax_pdfo = {
        'person_id': {table: 'Нарахування', text: 'Не заповнений працівник'},
        'month': {table: 'Нарахування', text: 'Не заповнений місяць пдфо'},
        'income_type_pix_id': {table: 'Нарахування', text: 'Не заповнений код доходу'},
        'chart_of_account': {table: 'Нарахування', text: 'Не заповнений бух.рахунок Дт'}
      }

      const table_accounting = {
        'person_id': {table: 'Нарахування', text: 'Не заповнений працівник'},
        'debit_chart_of_account_id': {table: 'Нарахування', text: 'Не заповнений рахунок Дт'},
        'debit_detail_1_id': {
          table: 'Нарахування',
          text: 'Не заповнене субконто 1 по Дт',
          condition: 'debit_detail_1_type'
        },
        'debit_detail_2_id': {
          table: 'Нарахування',
          text: 'Не заповнене субконто 2 по Дт',
          condition: 'debit_detail_2_type'
        },
        'debit_detail_3_id': {
          table: 'Нарахування',
          text: 'Не заповнене субконто 2 по Дт',
          condition: 'debit_detail_3_type'
        },
        'credit_chart_of_account_id': {table: 'Нарахування', text: 'Не заповнений рахунок Кт'},
        'credit_detail_1_id': {
          table: 'Нарахування',
          text: 'Не заповнене субконто 1 по Кт',
          condition: 'credit_detail_1_type'
        },
        'credit_detail_2_id': {
          table: 'Нарахування',
          text: 'Не заповнене субконто 2 по Кт',
          condition: 'credit_detail_2_type'
        },
        'credit_detail_3_id': {
          table: 'Нарахування',
          text: 'Не заповнене субконто 2 по Кт',
          condition: 'credit_detail_3_type'
        },
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      this.checkDocumentTable(table_charge, 'Нарахування', 'charges')
      this.checkDocumentTable(table_indexation, 'Індексація', 'indexation')
      this.checkDocumentTable(table_contribution, 'Внески', 'contribution')
      this.checkDocumentTable(table_tax_pdfo, 'ПДФО', 'tax_pdfo')
      this.checkDocumentTable(table_accounting, 'Проводки', 'accounting')

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = true
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }

    },
    closeModal() {
      this.$emit('closeModal')
      this.clearPersonData(this.item)

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення документа нарахування заробітної плати № ${this.number} від ${format_date_from_python(this.date)}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    deletePersonDialog(person) {
      const payload = {
        text: `Вилучити працівника ${person.full_name} із документа?`,
        accept_button: true,
        id: 'salary_charge_delete_person',
        payload: person
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    get_payload_for_crud() {
      const payload = {
        date: this.date,
        month: this.month,
        is_advance: this.is_advance,
        subdivision_id: this.subdivision_id,
        currency_id: this.currency_id,
        persons: this.persons,
        charge: this.charges,
      }

      if (!this.is_advance) {
        payload.indexation = this.indexation
        payload.contribution = this.contribution
        payload.taxes = this.tax_pdfo
        payload.accounting = this.accounting
      }

      if (!this.isNew) {
        payload.id = this.itemId
      }

      return payload
    },
    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
      }

      const payload = this.get_payload_for_crud()

      if (this.isNew) {
        this.$store.dispatch(CREATE_DOCUMENT_SALARY_CHARGE_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        this.$store.dispatch(UPDATE_DOCUMENT_SALARY_CHARGE_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    load_select_data() {
      return Promise.all([
        this.$store.dispatch(SELECT_TAXES_LIST_ELEMENTS),
        this.$store.dispatch(SELECT_ACCRUAL_LIST_ELEMENTS),
        this.$store.dispatch(SELECT_SUBDIVISION_LIST_ELEMENTS),
        this.$store.dispatch(SELECT_PERSONAL_LIST_ELEMENTS),
        this.$store.dispatch(SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS),
        this.$store.dispatch(SELECT_INCOME_TYPE_PIX_LIST_ELEMENTS),
      ])
    },
    fetch_data_by_id() {
      if (this.isNew) return

      salaryChargeAPI.get_document_by_id(this.itemId)
          .then(response => response.data)
          .then(data => {
            this.tab = 0
            this.date = data.date
            this.number = data.number
            this.month = data.month
            // this.is_advance = false
            this.subdivision_id = data.subdivision_id
            this.currency_id = data.currency_id

            this.persons = data.persons.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })
            this.charges = data.charge.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })
            this.indexation = data.indexation.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })
            this.contribution = data.contribution.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })
            this.tax_pdfo = data.taxes.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })
            this.accounting = data.accounting.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })
            this.calcResultAll()
            this.getTotals()
            if (!this.selected_person && this.persons.length) {
              this.selected_person = this.persons[0].person_id
            }
          })
    },
    watch_item() {
      this.watcher_item = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.clearPersonData(payload)
              this.fetch_data_by_id()
              this.load_select_data()
                  .then(() => {
                      this.getStartInfo()
                  })
            }
          }
      )
    },
    watch_dialog() {
      this.watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher_item) {
                  this.watcher_item()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_SALARY_CHARGE_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
              if (payload.id === 'salary_charge_delete_person') {
                if (payload.answer && payload.payload) {
                  this.deletePerson(payload.payload)
                }
              }
              if (payload.id === "charge_refill_id") {
                if (payload.answer) {
                  this.getStartInfo(this.fillDocumentByAll())
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      personSelection: 'get_personal_list',
    }),
    privilege_percent() {
      if (this.privilege_type) {
        const position = this.privilege_type.lastIndexOf('_')
        return this.privilege_type.substr(position + 1)
      } else {
        return '0'
      }
    },
    filter_charge() {
      return this.selected_person ? this.charges.filter(item => item.person_id === this.selected_person) : this.charges
    },
    filter_indexation() {
      return this.selected_person ? this.indexation.filter(item => item.person_id === this.selected_person) : this.indexation
    },
    filter_contribution() {
      return this.selected_person ? this.contribution.filter(item => item.person_id === this.selected_person) : this.contribution
    },
    filter_tax_pdfo() {
      return this.selected_person ? this.tax_pdfo.filter(item => item.person_id === this.selected_person) : this.tax_pdfo
    },
    filter_charge_result() {
      return this.selected_person ? this.charge_result.filter(item => item.person_id === this.selected_person) : this.charge_result
    },
    filter_accounting_result() {
      return this.selected_person ? this.accounting.filter(item => item.person_id === this.selected_person) : this.accounting
    }
  }
}
</script>

<style scoped lang="scss">
.person-list {
  .person-item {
    display: flex;
    padding: 4px 6px;
    background-color: white;
    margin-bottom: 8px;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }

    .person-order {
      padding-left: 2px;
      flex: 0 0 40px;
    }

    .person-title {
      flex: 1;
    }

    .person-selected-icon {
      flex: 0 0 30px;
    }
  }

  .person-item.selected {
    background-color: var(--v-success-base);

    &:hover {
      background-color: var(--v-success-base);
    }

    .person-order {
      color: white;
    }

    .person-title {
      color: white;
    }
  }
}
</style>